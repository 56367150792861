._2YTvOW_fullscreen {
  top: var(--spacing-0);
  left: var(--spacing-0);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: absolute;
}

._5R-KnW_error {
  top: var(--spacing-0);
  left: var(--spacing-0);
  background: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: absolute;
}

._5R-KnW_icon svg {
  width: var(--spacing-12);
  height: var(--spacing-12);
}

._30JWLq_app {
  padding: var(--spacing-6) var(--spacing-8);
  flex-direction: column;
  height: 100%;
  display: flex;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url("source-sans-pro-latin-200-normal.4ebd83e7.woff2") format("woff2"), url("source-sans-pro-latin-200-normal.b537903f.woff") format("woff");
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("source-sans-pro-latin-300-normal.5fb37018.woff2") format("woff2"), url("source-sans-pro-latin-300-normal.01776af8.woff") format("woff");
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("source-sans-pro-latin-400-normal.d7c2a62d.woff2") format("woff2"), url("source-sans-pro-latin-400-normal.5236ec11.woff") format("woff");
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("source-sans-pro-latin-600-normal.7320d84c.woff2") format("woff2"), url("source-sans-pro-latin-600-normal.8c6f57a3.woff") format("woff");
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("source-sans-pro-latin-700-normal.389eeccb.woff2") format("woff2"), url("source-sans-pro-latin-700-normal.0338754d.woff") format("woff");
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("source-sans-pro-latin-900-normal.46652465.woff2") format("woff2"), url("source-sans-pro-latin-900-normal.77995bba.woff") format("woff");
}

:root, .lc-legacy-theme {
  --background: #fff;
  --background-01: #fff;
  --background-02: #fff;
  --background-03: #f1f1f2;
  --surface-basic-default: #fff;
  --surface-primary-default: #fff;
  --surface-basic-subtle: #f1f6f8;
  --surface-secondary-default: #f1f6f8;
  --surface-secondary-hover: #e0e8eb;
  --surface-secondary-active: #dae4eb;
  --surface-secondary-disabled: #eaedf0;
  --surface-basic-hover: #e0e8eb;
  --surface-primary-hover: #f3f6f7;
  --surface-basic-active: #dae4eb;
  --surface-primary-active: #ebf1f4;
  --surface-primary-active-colored: #4284f540;
  --surface-basic-disabled: #eaedf0;
  --surface-primary-disabled: #f9fafb;
  --surface-tertiary-default: #dde4e9;
  --surface-secondary-subtle: #e4e8ec;
  --surface-moderate-default: #cad6dd;
  --surface-moderate-hover: #c3ced5;
  --surface-moderate-active: #bac7cf;
  --surface-tertiary-hover: #d3dde3;
  --surface-tertiary-active: #cdd8df;
  --surface-tertiary-disabled: #e4e8ec;
  --surface-feedback-info: #e5f3ff;
  --surface-accent-emphasis-low-info: #e6f3ff;
  --surface-feedback-negative: #fbeaed;
  --surface-accent-emphasis-low-negative: #fbeaed;
  --surface-feedback-warning: #fff8e0;
  --surface-accent-emphasis-low-warning: #fff8e0;
  --surface-feedback-positive: #e6f4ec;
  --surface-accent-emphasis-low-positive: #e6f4ec;
  --surface-accent-emphasis-low-purple: #eee8ff;
  --surface-accent-emphasis-min-info: #f4faff;
  --surface-accent-emphasis-min-negative: #fff8f8;
  --surface-accent-emphasis-min-warning: #fff9ed;
  --surface-accent-emphasis-min-positive: #f3fcf4;
  --surface-accent-emphasis-min-purple: #faf8ff;
  --surface-invert-default: #424d57;
  --surface-invert-primary: #424d57;
  --surface-invert-subtle: #677179;
  --surface-invert-secondary: #677179;
  --surface-invert-disabled: #a0a6ab;
  --surface-locked-default: #fff;
  --surface-locked-hover: #efeff0;
  --surface-locked-active: #daedff;
  --surface-locked-disabled: #ffffffb3;
  --surface-locked-white: #fff;
  --surface-locked-black: #000;
  --surface-accent-emphasis-medium-negative: #ffb7b7;
  --surface-accent-emphasis-medium-positive: #7edd92;
  --surface-accent-emphasis-high-info: #4284f5;
  --surface-accent-emphasis-high-negative: #d74747;
  --surface-accent-emphasis-high-warning: #fbbd04;
  --surface-accent-emphasis-high-positive: #22aa61;
  --surface-accent-emphasis-high-purple: #9642f5;
  --surface-overlay: #000000b3;
  --surface-other-overlay: #000000b3;
  --surface-other-agent: #4284f5;
  --surface-other-skeleton: #e5e8ec;
  --surface-gradient-01: linear-gradient(107.08deg, #8609ff 9.14%, #ee0007 111.43%);
  --surface-gradient-02: linear-gradient(107.08deg, #8609ff 9.14%, #0059e1 111.43%);
  --surface-gradient-03: linear-gradient(84deg, #ca0005 38%, #f3c600 140%);
  --surface-gradient-04: linear-gradient(95deg, #ffe9bc, #fffdf8 112%);
  --surface-gradient-05: linear-gradient(95deg, #daedff, #fbfdff 112%);
  --surface-gradient-06: linear-gradient(95deg, #eee8ff, #fdfdff 112%);
  --surface-gradient-07: linear-gradient(95deg, #d1f4d5, #e6f6ed 112%);
  --surface-gradient-08: linear-gradient(95deg, #ffe5e5, #fffcfc 112%);
  --surface-gradient-09: linear-gradient(95deg, #d1f4d5, #fff9ee 112%);
  --surface-gradient-10: linear-gradient(95deg, #eee8ff, #f4faff 112%);
  --surface-gradient-11: linear-gradient(95deg, #eee8ff, #fff4dd 112%);
  --content-default: #424d57;
  --content-basic-primary: #424d57;
  --content-subtle: #677179;
  --content-basic-secondary: #677179;
  --content-disabled: #a0a6ab;
  --content-basic-disabled: #a0a6ab;
  --content-basic-negative: #930002;
  --content-basic-warning: #755b00;
  --content-basic-positive: #005321;
  --content-basic-info: #003fa4;
  --content-basic-purple: #6100bd;
  --content-basic-gradient-01: linear-gradient(254.49deg, #8609ff -0.38%, #004bc2 94.44%);
  --content-white-locked: #fff;
  --content-locked-white: #fff;
  --content-black-locked: #424d57;
  --content-locked-black: #000;
  --content-locked-default: #424d57;
  --content-locked-hover: #131317;
  --content-locked-active: #003fa4;
  --content-locked-disabled: #8d8d95;
  --content-invert-default: #fff;
  --content-invert-primary: #fff;
  --content-invert-subtle: #ffffffb3;
  --content-invert-secondary: #ffffffb3;
  --content-invert-disabled: #ffffff80;
  --content-invert-gradient-01: linear-gradient(254.49deg, #b99aff -0.38%, #68afff 94.44%);
  --border-default: #bdc7d1;
  --border-basic-primary: #bdc7d1;
  --border-basic-secondary: #e4e8ec;
  --border-subtle: #e4e8ec;
  --border-basic-tertiary: #e9edf0;
  --border-hover: #a0a6ab;
  --border-basic-hover: #a0a6ab;
  --border-disabled: #dee3e8;
  --border-basic-disabled: #dee3e8;
  --border-basic-negative: #930002;
  --border-basic-warning: #755b00;
  --border-basic-positive: #005321;
  --border-basic-info: #003fa4;
  --border-basic-purple: #6100bd;
  --border-basic-contrast: #131317;
  --border-invert-default: #ffffffb3;
  --border-invert-primary: #ffffffb3;
  --border-invert-subtle: #ffffff4d;
  --border-invert-secondary: #ffffff4d;
  --border-invert-hover: #fff;
  --border-invert-disabled: #ffffff80;
  --color-action-active: #295fbc;
  --action-primary-active: #295fbc;
  --color-action-hover: #4379d6;
  --action-primary-hover: #4379d6;
  --color-action-default: #4284f5;
  --action-primary-default: #4284f5;
  --color-action-default-rgb: 66, 132, 245;
  --color-action-disabled: #a3c3fa;
  --action-primary-disabled: #a3c3fa;
  --color-negative-active: #94383a;
  --action-negative-active: #94383a;
  --color-negative-hover: #b94648;
  --action-negative-hover: #b94648;
  --color-negative-default: #d74747;
  --action-negative-default: #d74747;
  --color-negative-disabled: #eba3a3;
  --action-negative-disabled: #eba3a3;
  --color-warning-default: #fbbd04;
  --action-warning-default: #fbbd04;
  --color-warning-hover: #f99a01;
  --action-warning-hover: #f99a01;
  --action-warning-disabled: #e8bb0033;
  --color-positive-default: #22aa61;
  --action-positive-default: #22aa61;
  --color-positive-hover: #0d874a;
  --action-positive-hover: #0d874a;
  --color-positive-disabled: #90d5c4;
  --action-positive-disabled: #90d5c4;
  --action-neutral-default: #bdc7d1;
  --action-neutral-hover: #a0a6ab;
  --action-neutral-disabled: #dee3e8;
  --action-high-contrast-default: #1b1b20;
  --action-high-contrast-hover: #131317;
  --action-high-contrast-active: #131317;
  --action-high-contrast-disabled: #1b1b2033;
  --color-bot: #6b5aba;
  --surface-other-bot: #6b5aba;
  --color-black: #424d57;
  --color-white: #fff;
  --decor-blue900: #003288;
  --decor-blue800: #003fa4;
  --decor-blue700: #004bc2;
  --decor-blue600: #0059e1;
  --decor-blue500: #06f;
  --decor-blue400: #328dff;
  --decor-blue300: #68afff;
  --decor-blue200: #9dceff;
  --decor-blue100: #daedff;
  --decor-blue50: #f4faff;
  --decor-orange900: #6c1d00;
  --decor-orange800: #842600;
  --decor-orange700: #9c2e00;
  --decor-orange600: #b43700;
  --decor-orange500: #d54300;
  --decor-orange400: #ff5100;
  --decor-orange300: #ff8a77;
  --decor-orange200: #ffb8af;
  --decor-orange100: #ffe5e3;
  --decor-orange50: #fff8f7;
  --decor-yellow900: #493700;
  --decor-yellow800: #755b00;
  --decor-yellow700: #c9a100;
  --decor-yellow600: #e8bb00;
  --decor-yellow500: #f3c600;
  --decor-yellow400: #d1aa00;
  --decor-yellow300: #fed65e;
  --decor-yellow200: #fedc89;
  --decor-yellow100: #fff2d6;
  --decor-yellow50: #fff9ed;
  --decor-gray950: #131317;
  --decor-gray900: #1b1b20;
  --decor-gray800: #29292f;
  --decor-gray700: #3b3b43;
  --decor-gray600: #4e4e58;
  --decor-gray500: #62626d;
  --decor-gray400: #767680;
  --decor-gray300: #8d8d95;
  --decor-gray200: #ababb1;
  --decor-gray150: #c9c9cd;
  --decor-gray100: #c9c9cd;
  --decor-gray75: #eeeeef;
  --decor-gray50: #e2e2e4;
  --decor-gray40: #eeeeef;
  --decor-gray20: #fcfcfc;
  --decor-green900: #00431a;
  --decor-green800: #005321;
  --decor-green700: #006329;
  --decor-green600: #007331;
  --decor-green500: #00893c;
  --decor-green400: #00a449;
  --decor-green300: #00c057;
  --decor-green200: #7edd92;
  --decor-green100: #e4f4ed;
  --decor-green50: #f3fcf4;
  --decor-red900: #790002;
  --decor-red800: #930002;
  --decor-red700: #ae0003;
  --decor-red600: #ca0005;
  --decor-red500: #ee0007;
  --decor-red400: #ff4c4d;
  --decor-red300: #ff8889;
  --decor-red200: #ffb7b7;
  --decor-red100: #ffe5e5;
  --decor-red50: #fff8f8;
  --decor-purple900: #50009c;
  --decor-purple800: #6100bd;
  --decor-purple700: #7400df;
  --decor-purple600: #8609ff;
  --decor-purple500: #9146ff;
  --decor-purple400: #a372ff;
  --decor-purple300: #b99aff;
  --decor-purple200: #d1c0ff;
  --decor-purple100: #eee8ff;
  --decor-purple50: #faf8ff;
  --products-livechat: #fe5100;
  --products-helpdesk: #00c057;
  --products-chatbot: #06f;
  --products-knowledgebase: #9146ff;
  --illustrations-primary: #ffd000;
  --illustrations-secondary: #e2e2e4;
  --illustrations-stroke: #1b1b20;
  --tag-surface-01: #e4e8ec;
  --tag-content-01: #424d57;
  --btn-basic-background-enabled: #fff;
  --btn-basic-background-hover: #f3f6f7;
  --btn-basic-background-disabled: #f9fafb;
  --btn-basic-background-active: #f4faff;
  --btn-basic-background-loading: #f9fafb;
  --btn-secondary-background-enabled: #fff;
  --btn-secondary-background-hover: #f3f6f7;
  --btn-secondary-background-disabled: #f9fafb;
  --btn-secondary-background-active: #f4faff;
  --btn-secondary-background-loading: #f9fafb;
  --btn-plain-icon-background-hover: #f4faff;
  --btn-plain-icon-background-active: #4284f5;
  --picker-list-background: #fff;
  --picker-list-group-background: #f1f6f8;
  --picker-list-option-background-hover: #f3f6f7;
  --picker-list-option-background-active-hover: #d3dde3;
  --picker-list-option-background-focus: #f3f6f7;
  --picker-list-option-background-active: #ebf1f4;
  --popover-background: #fff;
  --surface-avatar-1: #8609ff;
  --surface-avatar-2: #ca0005;
  --surface-avatar-3: #328dff;
  --surface-avatar-4: #00893c;
  --surface-avatar-5: #9146ff;
  --surface-avatar-6: #ff5100;
  --surface-avatar-7: #06f;
  --surface-avatar-8: #a372ff;
  --surface-avatar-9: #b08c00;
  --surface-avatar-10: #00a449;
}

.lc-light-theme {
  --background: #fff;
  --background-01: #fff;
  --background-02: #fff;
  --background-03: #f1f1f2;
  --surface-basic-default: #fff;
  --surface-primary-default: #fff;
  --surface-basic-subtle: #f6f6f7;
  --surface-secondary-default: #f5f5f5;
  --surface-secondary-hover: #ececec;
  --surface-secondary-active: #e7e7e7;
  --surface-secondary-disabled: #f0f0f1;
  --surface-basic-hover: #e2e2e4;
  --surface-primary-hover: #f3f3f3;
  --surface-basic-active: #c9c9cd;
  --surface-primary-active: #eeeeef;
  --surface-primary-active-colored: #0066ff40;
  --surface-basic-disabled: #f6f6f7;
  --surface-primary-disabled: #f6f6f6;
  --surface-tertiary-default: #e6e6e7;
  --surface-secondary-subtle: #e4e8ec;
  --surface-moderate-default: #d8d8da;
  --surface-moderate-hover: #d1d1d4;
  --surface-moderate-active: #cdcdcf;
  --surface-tertiary-hover: #e4e4e5;
  --surface-tertiary-active: #e2e2e3;
  --surface-tertiary-disabled: #ebebec;
  --surface-feedback-info: #daedff;
  --surface-accent-emphasis-low-info: #daedff;
  --surface-feedback-negative: #ffe5e5;
  --surface-accent-emphasis-low-negative: #ffe5e5;
  --surface-feedback-warning: #fff2d6;
  --surface-accent-emphasis-low-warning: #ffe9bc;
  --surface-feedback-positive: #e4f4ed;
  --surface-accent-emphasis-low-positive: #d1f4d5;
  --surface-accent-emphasis-low-purple: #eee8ff;
  --surface-accent-emphasis-min-info: #f4faff;
  --surface-accent-emphasis-min-negative: #fff8f8;
  --surface-accent-emphasis-min-warning: #fff9ed;
  --surface-accent-emphasis-min-positive: #f3fcf4;
  --surface-accent-emphasis-min-purple: #faf8ff;
  --surface-invert-default: #1b1b20;
  --surface-invert-primary: #131317;
  --surface-invert-subtle: #4e4e58;
  --surface-invert-secondary: #3b3b43;
  --surface-invert-disabled: #8d8d95;
  --surface-locked-default: #fff;
  --surface-locked-hover: #eeeeef;
  --surface-locked-active: #daedff;
  --surface-locked-disabled: #ffffffb3;
  --surface-locked-white: #fff;
  --surface-locked-black: #000;
  --surface-accent-emphasis-medium-negative: #ffb7b7;
  --surface-accent-emphasis-medium-positive: #7edd92;
  --surface-accent-emphasis-high-info: #0059e1;
  --surface-accent-emphasis-high-negative: #ca0005;
  --surface-accent-emphasis-high-warning: #e8bb00;
  --surface-accent-emphasis-high-positive: #00893c;
  --surface-accent-emphasis-high-purple: #9146ff;
  --surface-overlay: #131317b3;
  --surface-other-overlay: #131317b3;
  --surface-other-agent: #0059e1;
  --surface-other-skeleton: #e2e2e4;
  --surface-gradient-01: linear-gradient(107.08deg, #8609ff 9.14%, #ee0007 111.43%);
  --surface-gradient-02: linear-gradient(107.08deg, #8609ff 9.14%, #0059e1 111.43%);
  --surface-gradient-03: linear-gradient(84deg, #ca0005 38%, #f3c600 140%);
  --surface-gradient-04: linear-gradient(95deg, #ffe9bc, #fffdf8 112%);
  --surface-gradient-05: linear-gradient(95deg, #daedff, #fbfdff 112%);
  --surface-gradient-06: linear-gradient(95deg, #eee8ff, #fdfdff 112%);
  --surface-gradient-07: linear-gradient(95deg, #d1f4d5, #e6f6ed 112%);
  --surface-gradient-08: linear-gradient(95deg, #ffe5e5, #fffcfc 112%);
  --surface-gradient-09: linear-gradient(95deg, #d1f4d5, #fff9ee 112%);
  --surface-gradient-10: linear-gradient(95deg, #eee8ff, #f4faff 112%);
  --surface-gradient-11: linear-gradient(95deg, #eee8ff, #fff4dd 112%);
  --content-default: #131317;
  --content-basic-primary: #131317;
  --content-subtle: #62626d;
  --content-basic-secondary: #62626d;
  --content-disabled: #8d8d95;
  --content-basic-disabled: #8d8d95;
  --content-basic-negative: #ae0003;
  --content-basic-warning: #755b00;
  --content-basic-positive: #005321;
  --content-basic-info: #003fa4;
  --content-basic-purple: #6100bd;
  --content-basic-gradient-01: linear-gradient(254.49deg, #8609ff -0.38%, #004bc2 94.44%);
  --content-white-locked: #fff;
  --content-locked-white: #fff;
  --content-black-locked: #131317;
  --content-locked-black: #000;
  --content-locked-default: #131317;
  --content-locked-hover: #131317;
  --content-locked-active: #003fa4;
  --content-locked-disabled: #8d8d95;
  --content-invert-default: #fff;
  --content-invert-primary: #fff;
  --content-invert-subtle: #ffffffb3;
  --content-invert-secondary: #ffffffb3;
  --content-invert-disabled: #ffffff80;
  --content-invert-gradient-01: linear-gradient(254.49deg, #b99aff -0.38%, #68afff 94.44%);
  --border-default: #c9c9cd;
  --border-basic-primary: #c9c9cd;
  --border-basic-secondary: #e2e2e4;
  --border-subtle: #eeeeef;
  --border-basic-tertiary: #e5e5e6;
  --border-hover: #ababb1;
  --border-basic-hover: #ababb1;
  --border-disabled: #e2e2e4;
  --border-basic-disabled: #e2e2e4;
  --border-basic-negative: #ae0003;
  --border-basic-warning: #755b00;
  --border-basic-positive: #005321;
  --border-basic-info: #003fa4;
  --border-basic-purple: #6100bd;
  --border-basic-contrast: #131317;
  --border-invert-default: #ffffffb3;
  --border-invert-primary: #ffffffb3;
  --border-invert-subtle: #ffffff4d;
  --border-invert-secondary: #ffffff4d;
  --border-invert-hover: #fff;
  --border-invert-disabled: #ffffff80;
  --color-action-active: #003fa4;
  --action-primary-active: #09367c;
  --color-action-hover: #004bc2;
  --action-primary-hover: #0644a4;
  --color-action-default: #06f;
  --action-primary-default: #0059e1;
  --color-action-default-rgb: 0, 102, 255;
  --color-action-disabled: #9dceff;
  --action-primary-disabled: #0059e133;
  --color-negative-active: #930002;
  --action-negative-active: #6f090e;
  --color-negative-hover: #ae0003;
  --action-negative-hover: #8d0004;
  --color-negative-default: #ca0005;
  --action-negative-default: #ca0005;
  --color-negative-disabled: #eba3a3;
  --action-negative-disabled: #ca000533;
  --color-warning-default: #e8bb00;
  --action-warning-default: #e8bb00;
  --color-warning-hover: #c9a100;
  --action-warning-hover: #a88907;
  --action-warning-disabled: #e8bb0033;
  --color-positive-default: #00893c;
  --action-positive-default: #00893c;
  --color-positive-hover: #006329;
  --action-positive-hover: #066631;
  --color-positive-disabled: #d1f4d5;
  --action-positive-disabled: #00893c33;
  --action-neutral-default: #8d8d95;
  --action-neutral-hover: #68686f;
  --action-neutral-disabled: #8d8d9533;
  --action-high-contrast-default: #1b1b20;
  --action-high-contrast-hover: #131317;
  --action-high-contrast-active: #131317;
  --action-high-contrast-disabled: #1b1b2033;
  --color-bot: #50009c;
  --surface-other-bot: #50009c;
  --color-black: #131317;
  --color-white: #fff;
  --decor-blue900: #003288;
  --decor-blue800: #003fa4;
  --decor-blue700: #004bc2;
  --decor-blue600: #0059e1;
  --decor-blue500: #06f;
  --decor-blue400: #328dff;
  --decor-blue300: #68afff;
  --decor-blue200: #9dceff;
  --decor-blue100: #daedff;
  --decor-blue50: #f4faff;
  --decor-orange900: #6c1d00;
  --decor-orange800: #842600;
  --decor-orange700: #9c2e00;
  --decor-orange600: #b43700;
  --decor-orange500: #d54300;
  --decor-orange400: #ff5100;
  --decor-orange300: #ff8a77;
  --decor-orange200: #ffb8af;
  --decor-orange100: #ffe5e3;
  --decor-orange50: #fff8f7;
  --decor-yellow900: #493700;
  --decor-yellow800: #755b00;
  --decor-yellow700: #c9a100;
  --decor-yellow600: #e8bb00;
  --decor-yellow500: #f3c600;
  --decor-yellow400: #d1aa00;
  --decor-yellow300: #fed65e;
  --decor-yellow200: #fedc89;
  --decor-yellow100: #fff2d6;
  --decor-yellow50: #fff9ed;
  --decor-gray950: #131317;
  --decor-gray900: #1b1b20;
  --decor-gray800: #29292f;
  --decor-gray700: #3b3b43;
  --decor-gray600: #4e4e58;
  --decor-gray500: #62626d;
  --decor-gray400: #767680;
  --decor-gray300: #8d8d95;
  --decor-gray200: #ababb1;
  --decor-gray150: #c9c9cd;
  --decor-gray100: #c9c9cd;
  --decor-gray75: #eeeeef;
  --decor-gray50: #e2e2e4;
  --decor-gray40: #eeeeef;
  --decor-gray20: #fcfcfc;
  --decor-green900: #00431a;
  --decor-green800: #005321;
  --decor-green700: #006329;
  --decor-green600: #007331;
  --decor-green500: #00893c;
  --decor-green400: #00a449;
  --decor-green300: #00c057;
  --decor-green200: #7edd92;
  --decor-green100: #e4f4ed;
  --decor-green50: #f3fcf4;
  --decor-red900: #790002;
  --decor-red800: #930002;
  --decor-red700: #ae0003;
  --decor-red600: #ca0005;
  --decor-red500: #ee0007;
  --decor-red400: #ff4c4d;
  --decor-red300: #ff8889;
  --decor-red200: #ffb7b7;
  --decor-red100: #ffe5e5;
  --decor-red50: #fff8f8;
  --decor-purple900: #50009c;
  --decor-purple800: #6100bd;
  --decor-purple700: #7400df;
  --decor-purple600: #8609ff;
  --decor-purple500: #9146ff;
  --decor-purple400: #a372ff;
  --decor-purple300: #b99aff;
  --decor-purple200: #d1c0ff;
  --decor-purple100: #eee8ff;
  --decor-purple50: #faf8ff;
  --products-livechat: #fe5100;
  --products-helpdesk: #00c057;
  --products-chatbot: #06f;
  --products-knowledgebase: #9146ff;
  --illustrations-primary: #ffd000;
  --illustrations-secondary: #e2e2e4;
  --illustrations-stroke: #1b1b20;
  --tag-surface-01: #e2e2e4;
  --tag-content-01: #131317;
  --btn-basic-background-enabled: #fff;
  --btn-basic-background-hover: #f3f3f3;
  --btn-basic-background-disabled: #fff;
  --btn-basic-background-active: #f4faff;
  --btn-basic-background-loading: #fff;
  --btn-secondary-background-enabled: #fff;
  --btn-secondary-background-hover: #f3f3f3;
  --btn-secondary-background-disabled: #fff;
  --btn-secondary-background-active: #f4faff;
  --btn-secondary-background-loading: #fff;
  --btn-plain-icon-background-hover: #f4faff;
  --btn-plain-icon-background-active: #0059e1;
  --picker-list-background: #fff;
  --picker-list-group-background: #f5f5f5;
  --picker-list-option-background-hover: #f3f3f3;
  --picker-list-option-background-active-hover: #e4e4e5;
  --picker-list-option-background-focus: #f3f3f3;
  --picker-list-option-background-active: #eeeeef;
  --popover-background: #fff;
  --surface-avatar-1: #8609ff;
  --surface-avatar-2: #ca0005;
  --surface-avatar-3: #328dff;
  --surface-avatar-4: #00893c;
  --surface-avatar-5: #9146ff;
  --surface-avatar-6: #ff5100;
  --surface-avatar-7: #06f;
  --surface-avatar-8: #a372ff;
  --surface-avatar-9: #b08c00;
  --surface-avatar-10: #00a449;
}

.lc-dark-theme {
  --background: #131317;
  --background-01: #131317;
  --background-02: #202024;
  --background-03: #202024;
  --surface-basic-default: #202024;
  --surface-primary-default: #202024;
  --surface-basic-subtle: #29292f;
  --surface-secondary-default: #2d2d33;
  --surface-secondary-hover: #343439;
  --surface-secondary-active: #3a3a40;
  --surface-secondary-disabled: #2d2d32;
  --surface-basic-hover: #ffffff1a;
  --surface-primary-hover: #2b2b2f;
  --surface-basic-active: #fff3;
  --surface-primary-active: #323236;
  --surface-primary-active-colored: #0066ff40;
  --surface-basic-disabled: #2d2d32;
  --surface-primary-disabled: #333338;
  --surface-tertiary-default: #3b3b43;
  --surface-secondary-subtle: #3b3b43;
  --surface-moderate-default: #4e4e58;
  --surface-moderate-hover: #55555f;
  --surface-moderate-active: #5c5c65;
  --surface-tertiary-hover: #43434b;
  --surface-tertiary-active: #4b4b52;
  --surface-tertiary-disabled: #3b3b43;
  --surface-feedback-info: #323640;
  --surface-accent-emphasis-low-info: #20324d;
  --surface-feedback-negative: #3a3136;
  --surface-accent-emphasis-low-negative: #492529;
  --surface-feedback-warning: #3a362b;
  --surface-accent-emphasis-low-warning: #3d361b;
  --surface-feedback-positive: #263532;
  --surface-accent-emphasis-low-positive: #163628;
  --surface-accent-emphasis-low-purple: #362c4d;
  --surface-accent-emphasis-min-info: #232a36;
  --surface-accent-emphasis-min-negative: #32262b;
  --surface-accent-emphasis-min-warning: #322e26;
  --surface-accent-emphasis-min-positive: #182c26;
  --surface-accent-emphasis-min-purple: #2b2836;
  --surface-invert-default: #eeeeef;
  --surface-invert-primary: #e2e2e4;
  --surface-invert-subtle: #c9c9cd;
  --surface-invert-secondary: #c9c9cd;
  --surface-invert-disabled: #29292f;
  --surface-locked-default: #f6f6f7;
  --surface-locked-hover: #fff;
  --surface-locked-active: #36363b;
  --surface-locked-disabled: #333338;
  --surface-locked-white: #fff;
  --surface-locked-black: #000;
  --surface-accent-emphasis-medium-negative: #5f2a2e;
  --surface-accent-emphasis-medium-positive: #0a4824;
  --surface-accent-emphasis-high-info: #06f;
  --surface-accent-emphasis-high-negative: #ca0005;
  --surface-accent-emphasis-high-warning: #e8bb00;
  --surface-accent-emphasis-high-positive: #00893c;
  --surface-accent-emphasis-high-purple: #9146ff;
  --surface-overlay: #131317e6;
  --surface-gradient-01: linear-gradient(107.08deg, #8609ff 9.14%, #ee0007 111.43%);
  --surface-gradient-02: linear-gradient(107.08deg, #8609ff 9.14%, #0059e1 111.43%);
  --surface-gradient-03: linear-gradient(84deg, #ae0003 38%, #f3c600 140%);
  --surface-gradient-04: linear-gradient(95deg, #5f5116, #322d1d 112%);
  --surface-gradient-05: linear-gradient(95deg, #244979, #1d2636 112%);
  --surface-gradient-06: linear-gradient(95deg, #513e79, #221f2b 112%);
  --surface-gradient-07: linear-gradient(95deg, #105230, #1a2222 112%);
  --surface-gradient-08: linear-gradient(95deg, #762f32, #261d22 112%);
  --surface-gradient-09: linear-gradient(95deg, #105230, #4b4119 112%);
  --surface-gradient-10: linear-gradient(95deg, #513e79, #1f2d44 112%);
  --surface-gradient-11: linear-gradient(95deg, #513e79, #423a1b 112%);
  --surface-other-overlay: #131317e6;
  --surface-other-agent: #0845a2;
  --surface-other-skeleton: #3b3b43;
  --content-default: #fffc;
  --content-basic-primary: #eeeeef;
  --content-subtle: #fff9;
  --content-basic-secondary: #ababb1;
  --content-disabled: #fff6;
  --content-basic-disabled: #767680;
  --content-basic-negative: #ff8282;
  --content-basic-warning: #e5c155;
  --content-basic-positive: #4cbf80;
  --content-basic-info: #68afff;
  --content-basic-purple: #bf9cff;
  --content-basic-gradient-01: linear-gradient(254.49deg, #b99aff -0.38%, #68afff 94.44%);
  --content-white-locked: #fff;
  --content-locked-white: #fff;
  --content-black-locked: #131317;
  --content-locked-black: #000;
  --content-locked-default: #131317;
  --content-locked-hover: #131317;
  --content-locked-active: #68afff;
  --content-locked-disabled: #767680;
  --content-invert-default: #131317;
  --content-invert-primary: #131317;
  --content-invert-subtle: #131317b3;
  --content-invert-secondary: #131317b3;
  --content-invert-disabled: #1313174d;
  --content-invert-gradient-01: linear-gradient(254.49deg, #8609ff -0.38%, #004bc2 94.44%);
  --border-default: #fff6;
  --border-basic-primary: #fff3;
  --border-basic-secondary: #ffffff1a;
  --border-subtle: #ffffff1a;
  --border-basic-tertiary: #131317;
  --border-hover: #fff9;
  --border-basic-hover: #fff9;
  --border-disabled: #ffffff80;
  --border-basic-disabled: #ffffff1a;
  --border-basic-negative: #ff7071;
  --border-basic-warning: #e6bb00;
  --border-basic-positive: #33b66d;
  --border-basic-info: #5ba4ff;
  --border-basic-purple: #b58eff;
  --border-basic-contrast: #eeeeef;
  --border-invert-default: #13131780;
  --border-invert-primary: #13131799;
  --border-invert-subtle: #1313171a;
  --border-invert-secondary: #1313171a;
  --border-invert-hover: #131317cc;
  --border-invert-disabled: #1313174d;
  --color-action-active: #b6d5fb;
  --action-primary-active: #b3d7ff;
  --color-action-hover: #8abbf9;
  --action-primary-hover: #86bfff;
  --color-action-default: #6daaf8;
  --action-primary-default: #68afff;
  --color-action-default-rgb: 157, 206, 255;
  --color-action-disabled: #6daaf8;
  --action-primary-disabled: #68afff4d;
  --color-negative-active: #ffe5e5;
  --action-negative-active: #ffa6a6;
  --color-negative-hover: #ffb7b7;
  --action-negative-hover: #ff7071;
  --color-negative-default: #ff5354;
  --action-negative-default: #ff5354;
  --color-negative-disabled: #ff4c4d4d;
  --action-negative-disabled: #ff4c4d4d;
  --color-warning-default: #fed65e;
  --action-warning-default: #e8bb00;
  --color-warning-hover: #fedc89;
  --action-warning-hover: #efcf4c;
  --action-warning-disabled: #e8bb004d;
  --color-positive-default: #00c057;
  --action-positive-default: #00a449;
  --color-positive-hover: #00e066;
  --action-positive-hover: #4cbf80;
  --color-positive-disabled: #4d735e;
  --action-positive-disabled: #00a44933;
  --action-neutral-default: #8d8d95;
  --action-neutral-hover: #afafb5;
  --action-neutral-disabled: #8d8d9533;
  --action-high-contrast-default: #e2e2e4;
  --action-high-contrast-hover: #fff;
  --action-high-contrast-active: #fff;
  --action-high-contrast-disabled: #e2e2e44d;
  --color-bot: #50009c;
  --surface-other-bot: #50009c;
  --color-black: #131317;
  --color-white: #fff;
  --decor-blue900: #003288;
  --decor-blue800: #003fa4;
  --decor-blue700: #004bc2;
  --decor-blue600: #0059e1;
  --decor-blue500: #06f;
  --decor-blue400: #328dff;
  --decor-blue300: #68afff;
  --decor-blue200: #9dceff;
  --decor-blue100: #daedff;
  --decor-blue50: #f4faff;
  --decor-orange900: #6c1d00;
  --decor-orange800: #842600;
  --decor-orange700: #9c2e00;
  --decor-orange600: #b43700;
  --decor-orange500: #d54300;
  --decor-orange400: #ff5100;
  --decor-orange300: #ff8a77;
  --decor-orange200: #ffb8af;
  --decor-orange100: #ffe5e3;
  --decor-orange50: #fff8f7;
  --decor-yellow900: #493700;
  --decor-yellow800: #755b00;
  --decor-yellow700: #c9a100;
  --decor-yellow600: #e8bb00;
  --decor-yellow500: #f3c600;
  --decor-yellow400: #d1aa00;
  --decor-yellow300: #fed65e;
  --decor-yellow200: #fedc89;
  --decor-yellow100: #fff2d6;
  --decor-yellow50: #fff9ed;
  --decor-gray950: #131317;
  --decor-gray900: #1b1b20;
  --decor-gray800: #29292f;
  --decor-gray700: #3b3b43;
  --decor-gray600: #4e4e58;
  --decor-gray500: #62626d;
  --decor-gray400: #767680;
  --decor-gray300: #8d8d95;
  --decor-gray200: #ababb1;
  --decor-gray150: #c9c9cd;
  --decor-gray100: #c9c9cd;
  --decor-gray75: #eeeeef;
  --decor-gray50: #e2e2e4;
  --decor-gray40: #eeeeef;
  --decor-gray20: #fcfcfc;
  --decor-green900: #00431a;
  --decor-green800: #005321;
  --decor-green700: #006329;
  --decor-green600: #007331;
  --decor-green500: #00893c;
  --decor-green400: #00a449;
  --decor-green300: #00c057;
  --decor-green200: #7edd92;
  --decor-green100: #e4f4ed;
  --decor-green50: #f3fcf4;
  --decor-red900: #790002;
  --decor-red800: #930002;
  --decor-red700: #ae0003;
  --decor-red600: #ca0005;
  --decor-red500: #ee0007;
  --decor-red400: #ff4c4d;
  --decor-red300: #ff8889;
  --decor-red200: #ffb7b7;
  --decor-red100: #ffe5e5;
  --decor-red50: #fff8f8;
  --decor-purple900: #50009c;
  --decor-purple800: #6100bd;
  --decor-purple700: #7400df;
  --decor-purple600: #8609ff;
  --decor-purple500: #9146ff;
  --decor-purple400: #a372ff;
  --decor-purple300: #b99aff;
  --decor-purple200: #d1c0ff;
  --decor-purple100: #eee8ff;
  --decor-purple50: #faf8ff;
  --products-livechat: #fe5100;
  --products-helpdesk: #00c057;
  --products-chatbot: #06f;
  --products-knowledgebase: #9146ff;
  --illustrations-primary: #3b3b43;
  --illustrations-secondary: #62626d;
  --illustrations-stroke: #c9c9cd;
  --tag-surface-01: #3b3b43;
  --tag-content-01: #eeeeef;
  --btn-basic-background-enabled: #202024;
  --btn-basic-background-hover: #2b2b2f;
  --btn-basic-background-disabled: #333338;
  --btn-basic-background-active: #232a36;
  --btn-basic-background-loading: #333338;
  --btn-secondary-background-enabled: #202024;
  --btn-secondary-background-hover: #2b2b2f;
  --btn-secondary-background-disabled: #333338;
  --btn-secondary-background-active: #232a36;
  --btn-secondary-background-loading: #333338;
  --btn-plain-icon-background-hover: #20324d;
  --btn-plain-icon-background-active: #68afff;
  --picker-list-background: #2d2d33;
  --picker-list-group-background: #3b3b43;
  --picker-list-option-background-hover: #343439;
  --picker-list-option-background-active-hover: #43434b;
  --picker-list-option-background-focus: #343439;
  --picker-list-option-background-active: #3a3a40;
  --popover-background: #2d2d33;
  --surface-avatar-1: #8609ff;
  --surface-avatar-2: #ca0005;
  --surface-avatar-3: #328dff;
  --surface-avatar-4: #00893c;
  --surface-avatar-5: #9146ff;
  --surface-avatar-6: #ff5100;
  --surface-avatar-7: #06f;
  --surface-avatar-8: #a372ff;
  --surface-avatar-9: #b08c00;
  --surface-avatar-10: #00a449;
}

:root {
  --spacing-0: 0;
  --spacing-05: 2px;
  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 12px;
  --spacing-4: 16px;
  --spacing-5: 20px;
  --spacing-6: 24px;
  --spacing-7: 28px;
  --spacing-8: 32px;
  --spacing-12: 48px;
  --spacing-14: 56px;
  --spacing-16: 64px;
  --spacing-18: 72px;
  --spacing-20: 80px;
  --spacing-24: 96px;
}

:root, .lc-legacy-theme {
  --shadow-float: 0 2px 6px 0 #424d5766;
  --shadow-pop-over: 0 6px 20px 0 #424d5766;
  --shadow-modal: 0 10px 50px 0 #424d5766;
  --shadow-tooltip: 0 1px 10px 0 #424d574d;
  --shadow-tooltip-arrow-bottom: 8px -6px 10px -2px #424d574d;
  --shadow-tooltip-arrow-top: -7px 6px 5px -3px #424d574d;
  --shadow-tooltip-arrow-right: -5px -5px 10px -2px #424d574d;
  --shadow-tooltip-arrow-left: 6px 6px 10px -2px #424d574d;
  --shadow-focus: 0 0 1px 2px #4284f5;
}

.lc-light-theme {
  --shadow-float: 0 2px 6px 0 #13131733;
  --shadow-pop-over: 0 6px 20px 0 #1313174d;
  --shadow-modal: 0 10px 50px 0 #13131766;
  --shadow-tooltip: 0 1px 10px 0 #424d574d;
  --shadow-tooltip-arrow-bottom: 8px -6px 10px -2px #424d574d;
  --shadow-tooltip-arrow-top: -7px 6px 5px -3px #424d574d;
  --shadow-tooltip-arrow-right: -5px -5px 10px -2px #424d574d;
  --shadow-tooltip-arrow-left: 6px 6px 10px -2px #424d574d;
  --shadow-focus: 0 0 1px 2px #0059e1;
}

.lc-dark-theme {
  --shadow-float: 0 2px 12px 0 #0000004d, 0 1px 4px 0 #0000004d, 0 0 0 1px #ffffff1a inset;
  --shadow-pop-over: 0 0 2px 1px #00000026, 0 4px 16px 1px #01010180, inset 0 0 0 1px #ffffff26;
  --shadow-modal: 0 10px 50px 0 #010101cc, 0 0 0 1px #ffffff26;
  --shadow-tooltip: 0 0 2px 1px #00000026, 0 4px 16px 1px #01010180, inset 0 0 0 1px #ffffff26;
  --shadow-tooltip-arrow-bottom: unset;
  --shadow-tooltip-arrow-top: unset;
  --shadow-tooltip-arrow-right: unset;
  --shadow-tooltip-arrow-left: unset;
  --shadow-focus: 0 0 1px 2px #68afff;
}

:root, .lc-legacy-theme, .lc-light-theme {
  --color-scheme: normal;
}

.lc-dark-theme {
  --color-scheme: dark;
}

:root {
  --radius-0: 0;
  --radius-1: 4px;
  --radius-2: 6px;
  --radius-3: 8px;
}

[class^="lc-"] {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif;
}

.lc-Typography-module__heading-xl___nhr-6 {
  letter-spacing: 0;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}

.lc-Typography-module__heading-lg___XYF7l {
  letter-spacing: 0;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.lc-Typography-module__heading-md___wHOIs {
  letter-spacing: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.lc-Typography-module__heading-sm___nBmTV {
  letter-spacing: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.lc-Typography-module__heading-xs___VcZBs {
  letter-spacing: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.lc-Typography-module__caps___c3eNJ {
  text-transform: uppercase;
  letter-spacing: .2px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.lc-Typography-module__caps--bold___OZovw {
  font-weight: 600;
}

.lc-Typography-module__paragraph-md___UGoqq {
  letter-spacing: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.lc-Typography-module__paragraph-md--bold___mWcMG {
  font-weight: 600;
}

.lc-Typography-module__paragraph-md--underline___Fv6zM {
  text-decoration-line: underline;
}

.lc-Typography-module__paragraph-md--strike___yLGHh {
  text-decoration-line: line-through;
}

.lc-Typography-module__paragraph-sm___QIEFL {
  letter-spacing: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.lc-Typography-module__paragraph-sm--bold___fAjI5 {
  font-weight: 600;
}

.lc-Typography-module__paragraph-xs___OUooD {
  letter-spacing: .2px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.lc-Typography-module__paragraph-xs--bold___z7om7 {
  font-weight: 600;
}

.lc-Typography-module__display-md___7eFCF {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.lc-Typography-module__display-sm___kfNES {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.lc-Popover-module__popover-trigger___SwsY- {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}

.lc-Popover-module__popover___8X1b2 {
  z-index: 1000;
  border-radius: var(--radius-3);
  box-shadow: var(--shadow-pop-over);
  background-color: var(--popover-background);
  min-width: 168px;
  max-width: 336px;
  display: none;
}

.lc-Popover-module__popover___8X1b2:focus {
  outline: none;
}

.lc-Popover-module__popover--visible___u5NXB {
  display: block;
}

.lc-ActionMenu-module__action-menu__trigger-button___tBrz5 {
  border-radius: var(--radius-3);
  background: none;
  border: 0;
  padding: 0;
}

.lc-ActionMenu-module__action-menu__trigger-button___tBrz5:hover {
  cursor: pointer;
}

.lc-ActionMenu-module__action-menu__trigger-button___tBrz5:focus-visible {
  outline: 2px solid var(--action-primary-default);
  outline-offset: 0;
}

.lc-ActionMenu-module__action-menu__list___9pNUX {
  border-radius: var(--radius-3);
  background-color: var(--picker-list-background);
  padding: 0;
  padding: var(--spacing-2);
  margin: 0;
  list-style: none;
  overflow: hidden;
}

.lc-ActionMenu-module__action-menu__list__group-header___n-DTq {
  z-index: 1;
  margin-bottom: var(--spacing-05);
  background-color: var(--picker-list-group-background);
  cursor: auto;
  text-transform: uppercase;
  color: var(--content-basic-secondary);
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding: 12px 12px 4px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  position: relative;
}

.lc-ActionMenu-module__action-menu__list__group-header___n-DTq:after {
  z-index: -1;
  background: var(--picker-list-group-background);
  content: "";
  height: 36px;
  position: absolute;
  top: 0;
  left: -8px;
  right: -8px;
}

.lc-ActionMenu-module__action-menu__list__group-header___n-DTq:first-of-type {
  margin-top: calc(var(--spacing-2) * -1);
}

.lc-ActionMenu-module__action-menu__list__item___n-OgH {
  margin-bottom: var(--spacing-05);
  border-radius: 0;
  border-radius: var(--radius-3);
  padding: 7px var(--spacing-3);
  text-align: left;
  color: var(--content-basic-primary);
  background: none;
  border: 0;
  outline: 0;
  align-items: center;
  width: 100%;
  min-height: 36px;
  display: flex;
  position: relative;
}

.lc-ActionMenu-module__action-menu__list__item___n-OgH:hover {
  background-color: var(--picker-list-option-background-hover);
  cursor: pointer;
}

.lc-ActionMenu-module__action-menu__list__item___n-OgH:active {
  background-color: var(--picker-list-option-background-active);
}

.lc-ActionMenu-module__action-menu__list__item___n-OgH:focus-visible {
  outline: 2px solid var(--action-primary-default);
  outline-offset: -2px;
}

.lc-ActionMenu-module__action-menu__list__item___n-OgH > * {
  pointer-events: none;
}

.lc-ActionMenu-module__action-menu__list__item--with-divider___2H3Vm {
  margin-top: var(--spacing-2);
}

.lc-ActionMenu-module__action-menu__list__item--with-divider___2H3Vm:before {
  background-color: var(--border-basic-secondary);
  content: "";
  height: 1px;
  position: absolute;
  top: -4.5px;
  left: -8px;
  right: -8px;
}

.lc-ActionMenu-module__action-menu__list__item--disabled___MgaX8 {
  color: var(--content-basic-disabled);
}

.lc-ActionMenu-module__action-menu__list__item--disabled___MgaX8:hover {
  cursor: not-allowed;
  background: none;
}

.lc-ActionMenuItem-module__action-menu-item___cT70J {
  flex-direction: row;
  flex: 1;
  align-items: center;
  display: flex;
}

.lc-ActionMenuItem-module__action-menu-item__left-node___3R-o1, .lc-ActionMenuItem-module__action-menu-item__right-node___uq0Ym {
  align-items: center;
  display: flex;
}

.lc-ActionMenuItem-module__action-menu-item__left-node___3R-o1 {
  margin-right: var(--spacing-2);
}

.lc-ActionMenuItem-module__action-menu-item__right-node___uq0Ym {
  margin-left: var(--spacing-2);
}

.lc-ActionMenuItem-module__action-menu-item__label___OgJ80 {
  flex: 1;
}

.lc-ActionMenuItem-module__action-menu-item--warning___DgeYg {
  color: var(--content-basic-negative);
}

.lc-Icon-module__icon___tw31R {
  align-items: center;
  display: flex;
}

.lc-Icon-module__icon___tw31R svg {
  pointer-events: none;
}

.lc-Icon-module__icon--primary___JJnBV {
  color: var(--content-basic-primary);
}

.lc-Icon-module__icon--subtle___sZIzL {
  color: var(--content-basic-secondary);
}

.lc-Icon-module__icon--inverted___zA9QC {
  color: var(--content-invert-primary);
}

.lc-Icon-module__icon--inverted-subtle___6UwBT {
  color: var(--content-invert-secondary);
}

.lc-Icon-module__icon--link___Jwkw9 {
  color: var(--surface-accent-emphasis-high-info);
}

.lc-Icon-module__icon--success___MKdz3 {
  color: var(--surface-accent-emphasis-high-positive);
}

.lc-Icon-module__icon--warning___5YCSW {
  color: var(--content-basic-warning);
}

.lc-Icon-module__icon--error___CiJCD {
  color: var(--surface-accent-emphasis-high-negative);
}

.lc-Icon-module__icon--negative___vfgpo {
  color: var(--content-basic-negative);
}

.lc-Icon-module__icon--positive___ulUuj {
  color: var(--content-basic-positive);
}

.lc-Icon-module__icon--info___Lfxfs {
  color: var(--content-basic-info);
}

.lc-Icon-module__icon--action-primary___n5UhF {
  color: var(--action-primary-default);
}

.lc-Icon-module__icon--action-negative___f1EPS {
  color: var(--action-negative-default);
}

.lc-Icon-module__icon--action-positive___o-6Q- {
  color: var(--action-positive-default);
}

.lc-Icon-module__icon--action-warning___zik06 {
  color: var(--action-warning-default);
}

.lc-Icon-module__icon--action-neutral___sTHAF {
  color: var(--action-neutral-default);
}

.lc-Icon-module__icon--disabled--primary___E0nee, .lc-Icon-module__icon--disabled--subtle___l-hK4 {
  color: var(--content-basic-disabled);
}

.lc-Icon-module__icon--disabled--inverted___5SBAf, .lc-Icon-module__icon--disabled--inverted-subtle___w-NBk {
  color: var(--content-invert-disabled);
}

.lc-Icon-module__icon--disabled--link___sVJOa {
  color: var(--action-primary-disabled);
}

.lc-Icon-module__icon--disabled--success___sG-7d {
  color: var(--action-positive-disabled);
}

.lc-Icon-module__icon--disabled--warning___8ko1e {
  color: var(--action-warning-disabled);
}

.lc-Icon-module__icon--disabled--error___9KbVo {
  color: var(--action-negative-disabled);
}

.lc-Icon-module__icon--disabled--negative___AzzCW, .lc-Icon-module__icon--disabled--positive___JelzZ, .lc-Icon-module__icon--disabled--info___3xl-B {
  color: var(--content-basic-disabled);
}

.lc-Icon-module__icon--disabled--action-primary___2k1TG {
  color: var(--action-primary-disabled);
}

.lc-Icon-module__icon--disabled--action-negative___Oraj8 {
  color: var(--action-negative-disabled);
}

.lc-Icon-module__icon--disabled--action-positive___zaLja {
  color: var(--action-positive-disabled);
}

.lc-Icon-module__icon--disabled--action-warning___5VvCf {
  color: var(--action-warning-disabled);
}

.lc-Icon-module__icon--disabled--action-neutral___SxlyC {
  color: var(--action-neutral-disabled);
}

@keyframes lc-Loader-module__rotate___Tlni- {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.lc-Loader-module__loader___LRflD {
  flex-flow: row;
  align-items: center;
  display: inline-flex;
}

.lc-Loader-module__loader__spinner___l3C1g {
  flex-shrink: 0;
  width: 36px;
  height: 36px;
}

.lc-Loader-module__loader__spinner--small___dGFFt {
  width: 16px;
  height: 16px;
}

.lc-Loader-module__loader__spinner--small___dGFFt [class*="spinner-circle"] {
  border-width: 2px;
}

.lc-Loader-module__loader__spinner--medium___J-ki0 {
  width: 36px;
  height: 36px;
}

.lc-Loader-module__loader__spinner--medium___J-ki0 [class*="spinner-circle"] {
  border-width: 3px;
}

.lc-Loader-module__loader__spinner--large___Poqq7 {
  width: 42px;
  height: 42px;
}

.lc-Loader-module__loader__spinner--large___Poqq7 [class*="spinner-circle"] {
  border-width: 4px;
}

.lc-Loader-module__loader__spinner-circle___ApBUO {
  box-sizing: border-box;
  border-style: solid;
  border-width: 3px;
  border-color: var(--border-basic-secondary);
  border-top-color: var(--action-primary-default);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: 1s linear infinite lc-Loader-module__rotate___Tlni-;
}

.lc-Loader-module__loader__label___R74f6 {
  color: var(--content-basic-secondary);
  margin-left: 10px;
}

.lc-Button-module__btn___aijZJ {
  box-sizing: border-box;
  border-radius: var(--radius-3);
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  border-style: solid;
  border-width: 1px;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  height: 36px;
  padding: 8px 16px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  transition-property: opacity, border, color, background-color, box-shadow;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.64, 0, .35, 1);
  display: inline-flex;
  position: relative;
}

.lc-Button-module__btn___aijZJ:focus, .lc-Button-module__btn___aijZJ:hover, .lc-Button-module__btn___aijZJ:active {
  outline: 0;
}

.lc-Button-module__btn--disabled___lXBav {
  cursor: not-allowed;
}

.lc-Button-module__btn--full-width___kdbAr {
  width: 100%;
}

.lc-Button-module__btn--basic___Jc2gD {
  border-color: var(--border-basic-primary);
  background-color: var(--btn-basic-background-enabled);
  color: var(--action-primary-default);
}

.lc-Button-module__btn--basic___Jc2gD:hover {
  border-color: var(--action-primary-default);
  background-color: var(--btn-basic-background-hover);
  color: var(--action-primary-hover);
}

.lc-Button-module__btn--basic___Jc2gD:active, .lc-Button-module__btn--basic___Jc2gD:focus {
  border-color: var(--action-primary-default);
  background-color: var(--btn-basic-background-active);
  color: var(--action-primary-default);
}

.lc-Button-module__btn--basic___Jc2gD[aria-disabled="true"] {
  border-color: var(--border-basic-disabled);
  background-color: var(--btn-basic-background-disabled);
  color: var(--action-primary-disabled);
}

.lc-Button-module__btn--primary___ajbjQ {
  border: 0;
  border-color: var(--action-primary-default);
  background-color: var(--action-primary-default);
  color: var(--content-invert-primary);
}

.lc-Button-module__btn--primary___ajbjQ:hover {
  border-color: var(--action-primary-hover);
  background-color: var(--action-primary-hover);
}

.lc-Button-module__btn--primary___ajbjQ:active, .lc-Button-module__btn--primary___ajbjQ:focus {
  border-color: var(--action-primary-active);
  background-color: var(--action-primary-active);
}

.lc-Button-module__btn--primary___ajbjQ[aria-disabled="true"] {
  border-color: var(--action-primary-disabled);
  background-color: var(--action-primary-disabled);
}

.lc-Button-module__btn--secondary___iWzE0 {
  border-color: var(--border-basic-primary);
  background-color: var(--btn-secondary-background-enabled);
  color: var(--content-default);
}

.lc-Button-module__btn--secondary___iWzE0:hover {
  border-color: var(--border-basic-hover);
  background-color: var(--btn-secondary-background-hover);
}

.lc-Button-module__btn--secondary___iWzE0:active, .lc-Button-module__btn--secondary___iWzE0:focus {
  border-color: var(--border-basic-contrast);
  background-color: var(--surface-primary-active);
  color: var(--content-basic-primary);
}

.lc-Button-module__btn--secondary___iWzE0[aria-disabled="true"] {
  border-color: var(--border-basic-disabled);
  background-color: var(--btn-secondary-background-disabled);
  color: var(--content-basic-disabled);
}

.lc-Button-module__btn--destructive___-kLCl {
  border: 0;
  border-color: var(--action-negative-default);
  background-color: var(--action-negative-default);
  color: var(--content-invert-primary);
}

.lc-Button-module__btn--destructive___-kLCl:hover {
  border-color: var(--action-negative-hover);
  background-color: var(--action-negative-hover);
}

.lc-Button-module__btn--destructive___-kLCl:active, .lc-Button-module__btn--destructive___-kLCl:focus {
  border-color: var(--action-negative-active);
  background-color: var(--action-negative-active);
}

.lc-Button-module__btn--destructive___-kLCl[aria-disabled="true"] {
  border-color: var(--action-negative-disabled);
  background-color: var(--action-negative-disabled);
}

.lc-Button-module__btn--destructive-outline___dexc3 {
  border-color: var(--action-negative-default);
  background-color: var(--surface-accent-emphasis-min-negative);
  color: var(--action-negative-default);
}

.lc-Button-module__btn--destructive-outline___dexc3:hover {
  border-color: var(--action-negative-hover);
  background-color: var(--surface-accent-emphasis-low-negative);
  color: var(--action-negative-hover);
}

.lc-Button-module__btn--destructive-outline___dexc3:active, .lc-Button-module__btn--destructive-outline___dexc3:focus {
  border-color: var(--action-negative-active);
  background-color: var(--surface-accent-emphasis-low-negative);
  color: var(--action-negative-active);
}

.lc-Button-module__btn--destructive-outline___dexc3[aria-disabled="true"] {
  border-color: var(--action-negative-disabled);
  background-color: var(--surface-primary-default);
  color: var(--action-negative-disabled);
}

.lc-Button-module__btn--compact___4eptQ {
  min-width: 32px;
  height: 32px;
  padding: 6px 16px;
}

.lc-Button-module__btn--compact___4eptQ.lc-Button-module__btn--icon-only___bF5pW {
  padding: 4px;
}

.lc-Button-module__btn--large___lb-z6 {
  min-width: 44px;
  height: 44px;
  padding: 11px 16px;
}

.lc-Button-module__btn--large___lb-z6.lc-Button-module__btn--icon-only___bF5pW {
  padding: 9px;
}

.lc-Button-module__btn--text___WA8OL {
  color: var(--action-high-contrast-default);
  background-color: #0000;
  border: 0;
  height: auto;
  padding: 0;
  text-decoration: underline;
}

.lc-Button-module__btn--text___WA8OL:hover, .lc-Button-module__btn--text___WA8OL:focus {
  color: var(--action-high-contrast-hover);
}

.lc-Button-module__btn--text___WA8OL:active {
  color: var(--action-high-contrast-active);
}

.lc-Button-module__btn--text___WA8OL[aria-disabled="true"] {
  color: var(--action-high-contrast-disabled);
}

.lc-Button-module__btn--link___51ZgF, .lc-Button-module__btn--link-light___iitZ6 {
  color: var(--action-primary-default);
  background: none;
  border: 0;
  min-width: auto;
  height: auto;
  padding: 0;
}

.lc-Button-module__btn--link___51ZgF:hover, .lc-Button-module__btn--link-light___iitZ6:hover {
  color: var(--action-primary-hover);
}

.lc-Button-module__btn--link___51ZgF:active, .lc-Button-module__btn--link___51ZgF:focus, .lc-Button-module__btn--link-light___iitZ6:active, .lc-Button-module__btn--link-light___iitZ6:focus {
  color: var(--action-primary-active);
}

.lc-Button-module__btn--link___51ZgF:hover, .lc-Button-module__btn--link___51ZgF:active, .lc-Button-module__btn--link___51ZgF:focus, .lc-Button-module__btn--link-light___iitZ6:hover, .lc-Button-module__btn--link-light___iitZ6:active, .lc-Button-module__btn--link-light___iitZ6:focus {
  box-shadow: none;
  text-decoration: underline;
}

.lc-Button-module__btn--link___51ZgF:disabled, .lc-Button-module__btn--link-light___iitZ6:disabled {
  text-decoration: none;
}

.lc-Button-module__btn--link___51ZgF[aria-disabled="true"], .lc-Button-module__btn--link-light___iitZ6[aria-disabled="true"] {
  color: var(--action-primary-disabled);
}

.lc-Button-module__btn--link___51ZgF[aria-disabled="true"]:hover, .lc-Button-module__btn--link___51ZgF[aria-disabled="true"]:active, .lc-Button-module__btn--link___51ZgF[aria-disabled="true"]:focus, .lc-Button-module__btn--link-light___iitZ6[aria-disabled="true"]:hover, .lc-Button-module__btn--link-light___iitZ6[aria-disabled="true"]:active, .lc-Button-module__btn--link-light___iitZ6[aria-disabled="true"]:focus {
  text-decoration: none;
}

.lc-Button-module__btn--link-light___iitZ6 {
  font-weight: 400;
}

.lc-Button-module__btn--link___51ZgF {
  font-weight: 600;
}

.lc-Button-module__btn--plain___eOPui {
  color: var(--content-basic-primary);
  background-color: #0000;
  border-color: #0000;
}

.lc-Button-module__btn--plain___eOPui:hover {
  background-color: var(--surface-primary-hover);
  color: var(--content-basic-primary);
  border-color: #0000;
}

.lc-Button-module__btn--plain___eOPui:active, .lc-Button-module__btn--plain___eOPui:focus {
  box-shadow: var(--shadow-focus), 0 0 0 2px #fff;
  background-color: var(--surface-primary-active);
  color: var(--content-basic-primary);
  border-color: #0000;
}

.lc-Button-module__btn--plain___eOPui[aria-disabled="true"] {
  color: var(--content-basic-disabled);
  background-color: #0000;
  border-color: #0000;
}

.lc-Button-module__btn--float___J4lTs {
  border-radius: var(--radius-3);
  box-shadow: var(--shadow-float);
  background-color: var(--surface-primary-default);
  color: var(--content-basic-primary);
  border-color: #0000;
}

.lc-Button-module__btn--float___J4lTs:hover, .lc-Button-module__btn--float___J4lTs:active, .lc-Button-module__btn--float___J4lTs:focus {
  background-color: var(--surface-primary-hover);
  color: var(--content-basic-primary);
  border-color: #0000;
}

.lc-Button-module__btn--float___J4lTs[aria-disabled="true"] {
  box-shadow: none;
  color: var(--content-basic-disabled);
  background-color: #0000;
  border-color: #0000;
}

.lc-Button-module__btn--dotted___y1EEP {
  border-style: dotted;
  border-color: var(--border-basic-primary);
  background-color: var(--btn-basic-background-enabled);
  color: var(--action-primary-default);
}

.lc-Button-module__btn--dotted___y1EEP:hover {
  border-color: var(--border-basic-hover);
  background-color: var(--surface-primary-active);
  color: var(--action-primary-default);
}

.lc-Button-module__btn--dotted___y1EEP:active, .lc-Button-module__btn--dotted___y1EEP:focus {
  border-color: var(--border-basic-primary);
  background-color: var(--surface-primary-active);
  color: var(--action-primary-default);
}

.lc-Button-module__btn--dotted___y1EEP[aria-disabled="true"] {
  border-color: var(--border-basic-disabled);
  background-color: var(--btn-basic-background-enabled);
  color: var(--action-primary-disabled);
}

.lc-Button-module__btn--high-contrast___zHSVM {
  background-color: var(--action-high-contrast-default);
  color: var(--content-invert-primary);
  border-color: #0000;
}

.lc-Button-module__btn--high-contrast___zHSVM:hover {
  background-color: var(--action-high-contrast-hover);
}

.lc-Button-module__btn--high-contrast___zHSVM:active, .lc-Button-module__btn--high-contrast___zHSVM:focus {
  background-color: var(--action-high-contrast-active);
}

.lc-Button-module__btn--high-contrast___zHSVM[aria-disabled="true"] {
  background-color: var(--action-high-contrast-disabled);
}

.lc-Button-module__btn--loading___ZAgjv {
  cursor: progress;
  transition: all .2s cubic-bezier(.64, 0, .35, 1);
}

.lc-Button-module__btn--loading___ZAgjv > * {
  opacity: 0;
}

.lc-Button-module__btn--loading___ZAgjv .lc-Button-module__btn__loader___c8zZz {
  box-sizing: border-box;
  opacity: 1;
  padding: inherit;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.lc-Button-module__btn--loading___ZAgjv .lc-Button-module__btn__loader___c8zZz > * {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: inherit;
  font-weight: inherit;
  margin: 0 5px;
  overflow: hidden;
}

.lc-Button-module__btn__icon___GVExB {
  color: inherit;
  width: 20px;
  height: 20px;
  line-height: 1;
  display: inline-block;
}

.lc-Button-module__btn__icon--left___H68zu {
  order: -1;
  margin-right: 4px;
}

.lc-Button-module__btn__icon--right___fHr3D {
  order: 1;
  margin-left: 4px;
}

.lc-Button-module__btn--icon-only___bF5pW {
  padding: 6px;
}

.lc-Button-module__btn--icon-only--bg___X-4V2:hover {
  background-color: var(--surface-primary-hover);
}

.lc-Button-module__btn--icon-only--bg___X-4V2:focus, .lc-Button-module__btn--icon-only--bg___X-4V2:active {
  background-color: var(--surface-primary-active);
}

.lc-Button-module__btn--icon-only--bg___X-4V2.lc-Button-module__btn--disabled___lXBav:focus, .lc-Button-module__btn--icon-only--bg___X-4V2.lc-Button-module__btn--disabled___lXBav:active, .lc-Button-module__btn--icon-only--bg___X-4V2.lc-Button-module__btn--disabled___lXBav:hover {
  background-color: #0000;
}

.lc-Button-module__btn--icon-only--bg___X-4V2.lc-Button-module__btn--text___WA8OL {
  min-width: 36px;
  height: 36px;
}

.lc-Button-module__btn--icon-only--bg___X-4V2.lc-Button-module__btn--text___WA8OL:focus, .lc-Button-module__btn--icon-only--bg___X-4V2.lc-Button-module__btn--text___WA8OL:active {
  background-color: var(--surface-primary-active);
}

.lc-Button-module__btn--icon-only--bg___X-4V2.lc-Button-module__btn--text___WA8OL.lc-Button-module__btn--compact___4eptQ {
  min-width: 32px;
  height: 32px;
}

.lc-Button-module__btn--icon-only--bg___X-4V2.lc-Button-module__btn--text___WA8OL.lc-Button-module__btn--large___lb-z6 {
  min-width: 44px;
  height: 44px;
}

.lc-Button-module__btn--icon-only--bg___X-4V2.lc-Button-module__btn--link___51ZgF {
  min-width: 36px;
  height: 36px;
}

.lc-Button-module__btn--icon-only--bg___X-4V2.lc-Button-module__btn--link___51ZgF:hover {
  background-color: var(--surface-accent-emphasis-min-info);
  color: var(--action-primary-hover);
}

.lc-Button-module__btn--icon-only--bg___X-4V2.lc-Button-module__btn--link___51ZgF:focus, .lc-Button-module__btn--icon-only--bg___X-4V2.lc-Button-module__btn--link___51ZgF:active {
  background-color: var(--action-primary-default);
  color: var(--content-invert-primary);
}

.lc-Button-module__btn--icon-only--bg___X-4V2.lc-Button-module__btn--link___51ZgF.lc-Button-module__btn--compact___4eptQ {
  min-width: 32px;
  height: 32px;
}

.lc-Button-module__btn--icon-only--bg___X-4V2.lc-Button-module__btn--link___51ZgF.lc-Button-module__btn--large___lb-z6 {
  min-width: 44px;
  height: 44px;
}

.lc-Button-module__btn--icon-only___bF5pW .lc-Button-module__btn__icon--left___H68zu, .lc-Button-module__btn--icon-only___bF5pW .lc-Button-module__btn__icon--right___fHr3D {
  margin: 0;
}

.lc-Tooltip-module__tooltip___QOOAr {
  z-index: 999999999;
  border-radius: var(--radius-3);
  border-color: var(--background-02);
  box-shadow: var(--shadow-tooltip);
  background-color: var(--background-02);
  padding: var(--spacing-3);
  color: var(--content-basic-primary);
  max-width: 320px;
}

.lc-Tooltip-module__tooltip___QOOAr:focus {
  outline: none;
}

.lc-Tooltip-module__tooltip__wrapper___dmjmW {
  display: inherit;
}

.lc-Tooltip-module__tooltip__interactive___0JawB, .lc-Tooltip-module__tooltip__user-guide-step___evexI {
  padding: var(--spacing-1);
  width: 270px;
}

.lc-Tooltip-module__tooltip--invert___PVjhE {
  border-color: var(--surface-invert-primary);
  background-color: var(--surface-invert-primary);
  color: var(--content-invert-primary);
}

.lc-Tooltip-module__tooltip--important___X-IMK {
  border-color: var(--surface-accent-emphasis-high-warning);
  background-color: var(--surface-accent-emphasis-high-warning);
  color: var(--content-locked-black);
}

.lc-Tooltip-module__tooltip--full-space___wmbaw {
  flex-wrap: wrap;
  max-width: 540px;
  padding: 0;
  display: flex;
}

.lc-Tooltip-module__tooltip__arrow___Ov1YW {
  border-color: inherit;
  color: inherit;
  width: 12px;
  height: 6px;
  margin: -6px 0;
  position: absolute;
}

.lc-Tooltip-module__tooltip__arrow___Ov1YW:after {
  content: "";
  border: 6px solid #0000;
  position: absolute;
  transform: rotate(-45deg);
}

.lc-Tooltip-module__tooltip__arrow___Ov1YW[data-arrow-placement*="bottom"] {
  height: 0;
  top: 1px;
}

.lc-Tooltip-module__tooltip__arrow___Ov1YW[data-arrow-placement*="bottom"]:after {
  border-top-color: inherit;
  border-right-color: inherit;
  box-shadow: var(--shadow-tooltip-arrow-bottom);
}

.lc-Tooltip-module__tooltip__arrow___Ov1YW[data-arrow-placement*="top"] {
  top: 100%;
}

.lc-Tooltip-module__tooltip__arrow___Ov1YW[data-arrow-placement*="top"]:after {
  border-bottom-color: inherit;
  border-left-color: inherit;
  box-shadow: var(--shadow-tooltip-arrow-top);
}

.lc-Tooltip-module__tooltip__arrow___Ov1YW[data-arrow-placement*="right"] {
  margin: -3px -5px;
  left: 0;
}

.lc-Tooltip-module__tooltip__arrow___Ov1YW[data-arrow-placement*="right"]:after {
  border-top-color: inherit;
  border-left-color: inherit;
  box-shadow: var(--shadow-tooltip-arrow-right);
}

.lc-Tooltip-module__tooltip__arrow___Ov1YW[data-arrow-placement*="left"] {
  width: 6px;
  height: 12px;
  margin: 0 -7px;
  left: 100%;
}

.lc-Tooltip-module__tooltip__arrow___Ov1YW[data-arrow-placement*="left"]:after {
  border-right-color: inherit;
  border-bottom-color: inherit;
  box-shadow: var(--shadow-tooltip-arrow-left);
}

.lc-Tooltip-module__tooltip-header___QV0Vd {
  margin-bottom: var(--spacing-1);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.lc-Tooltip-module__tooltip-header--info___zLJ2Z {
  padding-right: var(--spacing-5);
}

.lc-Tooltip-module__tooltip-image-container___ySmcd {
  margin-top: 30px;
}

.lc-Tooltip-module__tooltip-image___Aorwg {
  aspect-ratio: 16 / 9;
  border-radius: 4px;
  width: 100%;
  max-height: 138px;
  margin-bottom: 12px;
}

.lc-Tooltip-module__tooltip-text___FB8OV {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
}

.lc-Tooltip-module__tooltip-text--info___3d2Ql {
  padding-right: var(--spacing-5);
}

.lc-Tooltip-module__tooltip-close___3r3h9 {
  top: var(--spacing-2);
  right: var(--spacing-2);
  position: absolute;
}

.lc-Tooltip-module__tooltip-step___-j0n8 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.lc-Tooltip-module__tooltip-footer___9hxFq {
  margin: var(--spacing-4) 0 0;
  justify-content: space-between;
  display: flex;
}

.lc-Tooltip-module__tooltip-footer-secondary___WBgVR {
  margin-left: var(--spacing-4);
}

.lc-Tooltip-module__tooltip-footer-secondary-invert___PuAQL {
  color: var(--content-invert-primary);
}

.lc-Tooltip-module__tooltip-footer--interactive___hqMM5 {
  justify-content: flex-start;
}

.lc-Tooltip-module__tooltip-footer--user-guide-step___i7z3S {
  align-items: center;
}

.lc-Tooltip-module__guide-tooltip--slide___Uawm2 {
  transition-property: opacity, visibility, transform;
}

.lc-Tooltip-module__guide-tooltip__overlay___IyrKK {
  opacity: .5;
  background-color: var(--surface-invert-primary);
  display: none;
  position: fixed;
}

.lc-Tooltip-module__guide-tooltip__overlay--slide___VW8oq {
  transition-property: top, left, width, height;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.lc-Tooltip-module__guide-tooltip__overlay--visible___dbdsZ {
  display: block;
}

.lc-Tooltip-module__reports-tooltip___amyTo {
  flex: 270px;
  width: 270px;
}

.lc-Tooltip-module__reports-tooltip__heading___FtjvD {
  background-color: var(--surface-secondary-default);
  padding: var(--spacing-2) var(--spacing-4);
  text-align: center;
}

.lc-Tooltip-module__reports-tooltip__heading__title___6TlzE {
  color: var(--content-basic-primary);
}

.lc-Tooltip-module__reports-tooltip__heading__description___eOQnc {
  color: var(--content-basic-secondary);
}

.lc-Tooltip-module__reports-tooltip__content___jxis9 {
  padding: var(--spacing-1);
}

.lc-ActionBar-module__action-bar___TJitF {
  width: 100%;
  display: flex;
}

.lc-ActionBar-module__action-bar__items___uhoQA {
  padding: var(--spacing-2) var(--spacing-1);
  width: 100%;
  display: flex;
  overflow: hidden;
}

.lc-ActionBar-module__action-bar__items--scroll___f--dN {
  overflow: auto;
}

.lc-ActionBar-module__action-bar__items__button___Vdjl7 {
  margin-right: var(--spacing-1);
  margin-left: var(--spacing-1);
}

.lc-ActionBar-module__action-bar__items__button--active___PFLdm:after {
  border-top-left-radius: var(--radius-3);
  border-top-right-radius: var(--radius-3);
  background-color: var(--action-primary-default);
  content: "";
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: -7px;
}

.lc-ActionBar-module__action-bar__items__button--hidden___C00VA {
  opacity: 0;
  pointer-events: none;
}

.lc-ActionBar-module__action-bar__items__tooltip___BuQPo {
  display: grid;
}

.lc-ActionBar-module__action-bar__menu-wrapper___OMWP5 {
  align-items: center;
  display: flex;
}

.lc-Alert-module__alert___HBTns {
  box-sizing: border-box;
  border-radius: var(--radius-3);
  padding: var(--spacing-5);
  word-break: break-word;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.lc-Alert-module__alert__icon___8TaAH {
  margin-right: var(--spacing-5);
  align-items: flex-start;
  display: flex;
}

.lc-Alert-module__alert__content___9nZdf {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.lc-Alert-module__alert__content__text___7nx1p {
  margin-right: var(--spacing-8);
  color: var(--content-basic-primary);
  max-width: 720px;
}

.lc-Alert-module__alert__content__cta___gTWPt {
  margin-right: var(--spacing-8);
  flex-shrink: 0;
}

.lc-Alert-module__alert__content__cta__link___D976B {
  margin-left: var(--spacing-4);
}

.lc-Alert-module__alert__close-icon___rYxVy {
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.lc-Alert-module__alert--small___Fyk-v .lc-Alert-module__alert__content___9nZdf, .lc-Alert-module__alert--medium___ItZWT .lc-Alert-module__alert__content___9nZdf {
  flex-direction: column;
}

.lc-Alert-module__alert--small___Fyk-v .lc-Alert-module__alert__content__text___7nx1p, .lc-Alert-module__alert--medium___ItZWT .lc-Alert-module__alert__content__text___7nx1p {
  margin-bottom: var(--spacing-3);
}

.lc-Alert-module__alert--small___Fyk-v {
  flex-direction: column;
}

.lc-Alert-module__alert--small___Fyk-v .lc-Alert-module__alert__icon___8TaAH {
  margin-bottom: var(--spacing-3);
}

.lc-Alert-module__alert--small___Fyk-v .lc-Alert-module__alert__close-icon___rYxVy {
  top: var(--spacing-5);
  right: var(--spacing-5);
  position: absolute;
}

.lc-Alert-module__alert--info___GmIGN {
  background-color: var(--surface-accent-emphasis-low-info);
}

.lc-Alert-module__alert--warning___MubhD {
  background-color: var(--surface-accent-emphasis-low-warning);
}

.lc-Alert-module__alert--success___fPtlZ {
  background-color: var(--surface-accent-emphasis-low-positive);
}

.lc-Alert-module__alert--error___DKPo0 {
  background-color: var(--surface-accent-emphasis-low-negative);
}

.lc-Avatar-module__avatar___1zTCE {
  background-color: var(--surface-secondary-default);
  color: var(--content-basic-secondary);
  justify-content: center;
  align-items: center;
  font-weight: 600;
  display: flex;
  position: relative;
}

.lc-Avatar-module__avatar__status___nX2Ls {
  border: 1px solid var(--background-01);
  border-radius: 50%;
  position: absolute;
}

.lc-Avatar-module__avatar__status--available___tcpFg {
  background: var(--surface-accent-emphasis-high-positive);
}

.lc-Avatar-module__avatar__status--unavailable___ciauF {
  background: var(--action-negative-default);
}

.lc-Avatar-module__avatar__status--unknown___MBaGB {
  background: var(--surface-moderate-default);
}

.lc-Avatar-module__avatar__status--xxxsmall___8pkEO.lc-Avatar-module__avatar__status--circle___doV5H, .lc-Avatar-module__avatar__status--xxsmall___M69lx.lc-Avatar-module__avatar__status--circle___doV5H, .lc-Avatar-module__avatar__status--xsmall___RKQiI.lc-Avatar-module__avatar__status--circle___doV5H, .lc-Avatar-module__avatar__status--small___cVrA7.lc-Avatar-module__avatar__status--circle___doV5H, .lc-Avatar-module__avatar__status--medium___n5z1H.lc-Avatar-module__avatar__status--circle___doV5H, .lc-Avatar-module__avatar__status--large___Lt-0p.lc-Avatar-module__avatar__status--circle___doV5H, .lc-Avatar-module__avatar__status--xlarge___RWIcJ.lc-Avatar-module__avatar__status--circle___doV5H, .lc-Avatar-module__avatar__status--xxlarge___crG9D.lc-Avatar-module__avatar__status--circle___doV5H, .lc-Avatar-module__avatar__status--xxxlarge___Iv61X.lc-Avatar-module__avatar__status--circle___doV5H {
  bottom: 75%;
  left: 75%;
}

.lc-Avatar-module__avatar__status--xxxsmall___8pkEO.lc-Avatar-module__avatar__status--rounded-square___-My1P, .lc-Avatar-module__avatar__status--xxsmall___M69lx.lc-Avatar-module__avatar__status--rounded-square___-My1P, .lc-Avatar-module__avatar__status--xsmall___RKQiI.lc-Avatar-module__avatar__status--rounded-square___-My1P, .lc-Avatar-module__avatar__status--small___cVrA7.lc-Avatar-module__avatar__status--rounded-square___-My1P, .lc-Avatar-module__avatar__status--medium___n5z1H.lc-Avatar-module__avatar__status--rounded-square___-My1P, .lc-Avatar-module__avatar__status--large___Lt-0p.lc-Avatar-module__avatar__status--rounded-square___-My1P, .lc-Avatar-module__avatar__status--xlarge___RWIcJ.lc-Avatar-module__avatar__status--rounded-square___-My1P, .lc-Avatar-module__avatar__status--xxlarge___crG9D.lc-Avatar-module__avatar__status--rounded-square___-My1P, .lc-Avatar-module__avatar__status--xxxlarge___Iv61X.lc-Avatar-module__avatar__status--rounded-square___-My1P {
  bottom: 81.25%;
  left: 81.25%;
}

.lc-Avatar-module__avatar__status--xxxsmall___8pkEO {
  border-width: .5px;
  width: 4px;
  height: 4px;
}

.lc-Avatar-module__avatar__status--xxsmall___M69lx {
  border-width: .625px;
  width: 5px;
  height: 5px;
}

.lc-Avatar-module__avatar__status--xsmall___RKQiI {
  border-width: .75px;
  width: 6px;
  height: 6px;
}

.lc-Avatar-module__avatar__status--small___cVrA7 {
  border-width: 1px;
  width: 8px;
  height: 8px;
}

.lc-Avatar-module__avatar__status--medium___n5z1H {
  border-width: 1px;
  width: 9px;
  height: 9px;
}

.lc-Avatar-module__avatar__status--large___Lt-0p {
  border-width: 1.25px;
  width: 10px;
  height: 10px;
}

.lc-Avatar-module__avatar__status--xlarge___RWIcJ {
  border-width: 1.5px;
  width: 12px;
  height: 12px;
}

.lc-Avatar-module__avatar__status--xxlarge___crG9D {
  border-width: 2px;
  width: 16px;
  height: 16px;
}

.lc-Avatar-module__avatar__status--xxxlarge___Iv61X {
  border-width: 3px;
  width: 24px;
  height: 24px;
}

.lc-Avatar-module__avatar__rim___RCQ8Q {
  box-sizing: content-box;
  border-radius: inherit;
  border-style: solid;
  border-color: var(--action-negative-default);
  background: none;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lc-Avatar-module__avatar__rim--xxxsmall___0CH9D, .lc-Avatar-module__avatar__rim--xxsmall___Mde0K, .lc-Avatar-module__avatar__rim--xsmall___LAN-b {
  border-width: 2px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
}

.lc-Avatar-module__avatar__rim--small___FT8LX, .lc-Avatar-module__avatar__rim--medium___Dy0pr, .lc-Avatar-module__avatar__rim--large___FPvGp, .lc-Avatar-module__avatar__rim--xlarge___CY0kN, .lc-Avatar-module__avatar__rim--xxlarge___tE053, .lc-Avatar-module__avatar__rim--xxxlarge___amlGP {
  border-width: 3px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}

.lc-Avatar-module__avatar__image___YqfqE {
  border-radius: inherit;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.lc-Avatar-module__avatar__icon--xxxsmall___KaoMa svg {
  width: 8px;
  height: 8px;
}

.lc-Avatar-module__avatar__icon--xxsmall___NJJNV svg {
  width: 10px;
  height: 10px;
}

.lc-Avatar-module__avatar__icon--xsmall___yHEZK svg {
  width: 12px;
  height: 12px;
}

.lc-Avatar-module__avatar__icon--small___l6lBu svg {
  width: 16px;
  height: 16px;
}

.lc-Avatar-module__avatar__icon--medium___LF4w2 svg {
  width: 18px;
  height: 18px;
}

.lc-Avatar-module__avatar__icon--large___QntBf svg {
  width: 24px;
  height: 24px;
}

.lc-Avatar-module__avatar__icon--xlarge___bcHbI svg {
  width: 32px;
  height: 32px;
}

.lc-Avatar-module__avatar__icon--xxlarge___sx8Fq svg, .lc-Avatar-module__avatar__icon--xxxlarge___4144S svg {
  width: 48px;
  height: 48px;
}

.lc-Avatar-module__avatar--circle___XwEz- {
  border-radius: 50%;
}

.lc-Avatar-module__avatar--rounded-square___m7-4I {
  border-radius: 4px;
}

.lc-Avatar-module__avatar--xxxsmall___EHzpP {
  width: 16px;
  height: 16px;
  font-size: 12px;
  line-height: 20px;
}

.lc-Avatar-module__avatar--xxsmall___EDOVp {
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
}

.lc-Avatar-module__avatar--xsmall___nI348 {
  width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 20px;
}

.lc-Avatar-module__avatar--small___UuQen {
  width: 32px;
  height: 32px;
  font-size: 15px;
  line-height: 22px;
}

.lc-Avatar-module__avatar--medium___Bt9Eo {
  width: 36px;
  height: 36px;
  font-size: 15px;
  line-height: 22px;
}

.lc-Avatar-module__avatar--large___OU-08 {
  width: 40px;
  height: 40px;
  font-size: 18px;
  line-height: 24px;
}

.lc-Avatar-module__avatar--xlarge___zwgeW {
  width: 48px;
  height: 48px;
  font-size: 18px;
  line-height: 24px;
}

.lc-Avatar-module__avatar--xxlarge___Ur0ul {
  width: 64px;
  height: 64px;
  font-size: 24px;
  line-height: 32px;
}

.lc-Avatar-module__avatar--xxxlarge___Z-54U {
  width: 96px;
  height: 96px;
  font-size: 32px;
  line-height: 40px;
}

.lc-Badge-module__badge___GhLnu {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  display: inline-flex;
}

.lc-Badge-module__badge__dot___z6RO6 {
  background-color: var(--content-invert-primary);
  margin: 0 -2px;
}

.lc-Badge-module__badge___GhLnu + .lc-Badge-module__badge___GhLnu {
  margin-left: 4px;
}

.lc-Badge-module__badge--large___B131q {
  border-radius: 24px;
  min-width: 24px;
  height: 24px;
  padding: 4px 7px;
}

.lc-Badge-module__badge--large___B131q .lc-Badge-module__badge__dot___z6RO6 {
  border-radius: 10px;
  width: 10px;
  height: 10px;
}

.lc-Badge-module__badge--medium___TDU8a {
  border-radius: 20px;
  min-width: 20px;
  height: 20px;
  padding: 2px 6px;
}

.lc-Badge-module__badge--medium___TDU8a .lc-Badge-module__badge__dot___z6RO6 {
  border-radius: 8px;
  width: 8px;
  height: 8px;
}

.lc-Badge-module__badge--compact___9xJ4y {
  border-radius: 16px;
  min-width: 16px;
  height: 16px;
  padding: 1px 5px;
}

.lc-Badge-module__badge--compact___9xJ4y .lc-Badge-module__badge__dot___z6RO6 {
  border-radius: 6px;
  width: 6px;
  height: 6px;
}

.lc-Badge-module__badge--primary___8LRpp {
  background-color: var(--surface-accent-emphasis-high-negative);
  color: var(--content-locked-white);
}

.lc-Badge-module__badge--primary___8LRpp .lc-Badge-module__badge__dot___z6RO6 {
  background-color: var(--content-locked-white);
}

.lc-Badge-module__badge--secondary___JjNIJ {
  background-color: var(--surface-accent-emphasis-high-info);
  color: var(--content-locked-white);
}

.lc-Badge-module__badge--secondary___JjNIJ .lc-Badge-module__badge__dot___z6RO6 {
  background-color: var(--content-locked-white);
}

.lc-Badge-module__badge--tertiary___e83wV {
  background-color: var(--surface-tertiary-default);
  color: var(--content-basic-primary);
}

.lc-Badge-module__badge--tertiary___e83wV .lc-Badge-module__badge__dot___z6RO6 {
  background-color: var(--content-basic-primary);
}

.lc-SegmentedControl-module__segmented-control___LHWSk {
  border: 1px solid var(--border-basic-secondary);
  border-radius: var(--radius-3);
  background-color: var(--surface-primary-default);
  vertical-align: middle;
  flex-direction: row;
  padding: 3px;
  display: inline-flex;
}

.lc-SegmentedControl-module__segmented-control___LHWSk .lc-SegmentedControl-module__btn___tfsvL {
  border-radius: var(--radius-2);
  border-color: #0000;
}

.lc-SegmentedControl-module__segmented-control___LHWSk .lc-SegmentedControl-module__btn___tfsvL:last-of-type:after {
  display: none;
}

.lc-SegmentedControl-module__segmented-control___LHWSk .lc-SegmentedControl-module__btn___tfsvL[aria-pressed="false"][aria-disabled="false"]:after {
  background-color: var(--border-basic-secondary);
  content: "";
  width: 1px;
  height: 20px;
  position: absolute;
  right: -4px;
}

.lc-SegmentedControl-module__segmented-control___LHWSk .lc-SegmentedControl-module__btn--active___Za-th {
  background-color: var(--surface-primary-active);
  color: var(--content-basic-primary);
}

.lc-SegmentedControl-module__segmented-control___LHWSk .lc-SegmentedControl-module__btn--compact___gcSwq {
  height: 24px;
}

.lc-SegmentedControl-module__segmented-control___LHWSk .lc-SegmentedControl-module__btn--medium___w3UzC {
  height: 28px;
}

.lc-SegmentedControl-module__segmented-control___LHWSk .lc-SegmentedControl-module__btn--large___yHPw4 {
  height: 36px;
}

.lc-SegmentedControl-module__segmented-control___LHWSk .lc-SegmentedControl-module__btn___tfsvL + .lc-SegmentedControl-module__btn___tfsvL {
  margin-left: 5px;
}

.lc-Card-module__card___GqMm2 {
  border: solid 1px var(--border-basic-secondary);
  border-radius: var(--radius-3);
  background-color: var(--surface-primary-default);
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;
  display: flex;
}

.lc-Card-module__card__header___0ptfD {
  flex-direction: row;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 48px;
  padding: 0;
  display: flex;
}

.lc-Card-module__card__header__image___QMwSu {
  background-color: var(--surface-basic-disabled);
  border-radius: 4px;
  width: 48px;
  height: 48px;
}

.lc-Card-module__card__header__heading___Wkikx {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.lc-Card-module__card__header__heading__title___Uxi8s {
  color: var(--content-basic-primary);
  margin: 0;
}

.lc-Card-module__card__header__heading__description___6LnOK {
  color: var(--content-basic-secondary);
  margin: 0;
}

.lc-Card-module__card__header__no-image___UcnNY {
  height: 16px;
}

.lc-Card-module__card__content___KuDcc, .lc-Card-module__card__expanded-content___yB-QL {
  color: var(--content-basic-primary);
  gap: 10px;
  width: 100%;
  margin: 0;
}

.lc-Card-module__card__actions___q9sAo {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
}

.lc-Card-module__card__actions__line___TF1bt {
  background-color: var(--border-basic-secondary);
  width: 100%;
  height: 1px;
}

.lc-Card-module__card__actions__buttons___PDMMR {
  align-items: center;
  gap: 12px;
  width: 100%;
  display: flex;
}

.lc-Card-module__card__actions__buttons-expander___lz4h- {
  margin-left: auto;
}

.lc-FieldDescription-module__field-description___IcRDH {
  color: var(--content-subtle);
  display: block;
}

.lc-Checkbox-module__checkbox___G7nTf {
  display: inline-block;
}

.lc-Checkbox-module__checkbox__label___-o01x {
  cursor: pointer;
  flex: 0 auto;
  align-items: center;
  width: 100%;
  display: inline-flex;
}

.lc-Checkbox-module__checkbox__text___P2SIq {
  color: var(--content-basic-primary);
  flex: 1 0 auto;
  margin-left: 8px;
}

.lc-Checkbox-module__checkbox__input___YLbVF {
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border: 1px solid var(--border-basic-primary);
  background-color: var(--surface-primary-default);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin: 0;
  transition: all .2s;
  display: flex;
  position: relative;
}

.lc-Checkbox-module__checkbox__input___YLbVF:after {
  opacity: 0;
  z-index: 1;
  background-color: var(--content-invert-primary);
  content: "";
  width: 24px;
  height: 24px;
  transition: opacity .2s;
  display: inline-block;
  position: absolute;
  -webkit-mask: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTJweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTIgMTIiIHZlcnNpb249IjEuMSI+CjxnIGlkPSJzdXJmYWNlMSI+CjxwYXRoIHN0eWxlPSIgc3Ryb2tlOm5vbmU7ZmlsbC1ydWxlOm5vbnplcm87ZmlsbDpyZ2IoMjUuODgyMzUzJSwzMC4xOTYwNzglLDM0LjExNzY0NyUpO2ZpbGwtb3BhY2l0eToxOyIgZD0iTSA0LjUgOC4xMDE1NjIgTCAyLjc1IDYuMzUxNTYyIEMgMi41NTQ2ODggNi4xNTYyNSAyLjI0NjA5NCA2LjE1NjI1IDIuMDUwNzgxIDYuMzUxNTYyIEMgMS44NTU0NjkgNi41NDY4NzUgMS44NTU0NjkgNi44NTU0NjkgMi4wNTA3ODEgNy4wNTA3ODEgTCA0LjE0NDUzMSA5LjE0NDUzMSBDIDQuMzM5ODQ0IDkuMzM5ODQ0IDQuNjU2MjUgOS4zMzk4NDQgNC44NTE1NjIgOS4xNDQ1MzEgTCAxMC4xNDg0MzggMy44NTE1NjIgQyAxMC4zNDM3NSAzLjY1NjI1IDEwLjM0Mzc1IDMuMzQzNzUgMTAuMTQ4NDM4IDMuMTQ4NDM4IEMgOS45NTMxMjUgMi45NTMxMjUgOS42NDQ1MzEgMi45NTMxMjUgOS40NDkyMTkgMy4xNDg0MzggWiBNIDQuNSA4LjEwMTU2MiAiLz4KPC9nPgo8L3N2Zz4K") 50% no-repeat;
  mask: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTJweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTIgMTIiIHZlcnNpb249IjEuMSI+CjxnIGlkPSJzdXJmYWNlMSI+CjxwYXRoIHN0eWxlPSIgc3Ryb2tlOm5vbmU7ZmlsbC1ydWxlOm5vbnplcm87ZmlsbDpyZ2IoMjUuODgyMzUzJSwzMC4xOTYwNzglLDM0LjExNzY0NyUpO2ZpbGwtb3BhY2l0eToxOyIgZD0iTSA0LjUgOC4xMDE1NjIgTCAyLjc1IDYuMzUxNTYyIEMgMi41NTQ2ODggNi4xNTYyNSAyLjI0NjA5NCA2LjE1NjI1IDIuMDUwNzgxIDYuMzUxNTYyIEMgMS44NTU0NjkgNi41NDY4NzUgMS44NTU0NjkgNi44NTU0NjkgMi4wNTA3ODEgNy4wNTA3ODEgTCA0LjE0NDUzMSA5LjE0NDUzMSBDIDQuMzM5ODQ0IDkuMzM5ODQ0IDQuNjU2MjUgOS4zMzk4NDQgNC44NTE1NjIgOS4xNDQ1MzEgTCAxMC4xNDg0MzggMy44NTE1NjIgQyAxMC4zNDM3NSAzLjY1NjI1IDEwLjM0Mzc1IDMuMzQzNzUgMTAuMTQ4NDM4IDMuMTQ4NDM4IEMgOS45NTMxMjUgMi45NTMxMjUgOS42NDQ1MzEgMi45NTMxMjUgOS40NDkyMTkgMy4xNDg0MzggWiBNIDQuNSA4LjEwMTU2MiAiLz4KPC9nPgo8L3N2Zz4K") 50% no-repeat;
}

.lc-Checkbox-module__checkbox__input___YLbVF:checked:after {
  opacity: 1;
}

.lc-Checkbox-module__checkbox__input___YLbVF:checked {
  border-color: var(--color-action-default);
  background-color: var(--color-action-default);
}

.lc-Checkbox-module__checkbox__input___YLbVF:checked .lc-Checkbox-module__checkbox__checkmark___16pfY {
  opacity: 1;
}

.lc-Checkbox-module__checkbox__input___YLbVF:focus {
  box-shadow: var(--shadow-focus);
}

.lc-Checkbox-module__checkbox--selected___S2vqY:hover .lc-Checkbox-module__checkbox__input___YLbVF:checked + .lc-Checkbox-module__checkbox__square___MHUyd {
  border-color: var(--action-primary-hover);
  background-color: var(--action-primary-hover);
}

.lc-Checkbox-module__checkbox--selected___S2vqY .lc-Checkbox-module__checkbox__square___MHUyd {
  border-color: var(--action-primary-default);
  background-color: var(--action-primary-default);
}

.lc-Checkbox-module__checkbox--disabled___-lAqW .lc-Checkbox-module__checkbox__input___YLbVF {
  opacity: .4;
  border-color: var(--border-disabled);
  background-color: var(--surface-basic-disabled);
}

.lc-Checkbox-module__checkbox--disabled___-lAqW .lc-Checkbox-module__checkbox__label___-o01x, .lc-Checkbox-module__checkbox--disabled___-lAqW .lc-Checkbox-module__checkbox__input___YLbVF, .lc-Checkbox-module__checkbox--disabled___-lAqW .lc-Checkbox-module__checkbox__helper___YSJ-n, .lc-Checkbox-module__checkbox--disabled___-lAqW .lc-Checkbox-module__checkbox__text___P2SIq {
  cursor: not-allowed;
  color: var(--content-disabled);
}

.lc-Checkbox-module__checkbox__helper___YSJ-n {
  cursor: default;
  margin-left: 24px;
}

.lc-DatePicker-module__date-picker___9AqJ2 {
  color: var(--content-basic-primary);
  display: inline-block;
}

.lc-DatePicker-module__date-picker___9AqJ2:not(.lc-DatePicker-module__date-picker--interaction-disabled___-zn8o) .lc-DatePicker-module__date-picker__day___ZTSw9:not(.lc-DatePicker-module__date-picker__day--disabled___4O6yW):not(.lc-DatePicker-module__date-picker__day--selected___1D1tC):not(.lc-DatePicker-module__date-picker__day--outside___ptNdC):hover .lc-DatePicker-module__date-picker__day-content___rDIa0 {
  background-color: var(--surface-primary-hover);
  border-radius: 2px;
}

.lc-DatePicker-module__date-picker__wrapper___tNp4l {
  border-radius: var(--radius-3);
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #0000;
  flex-direction: row;
  padding-bottom: 10px;
  transition: border-color .2s ease-in-out;
  position: relative;
}

.lc-DatePicker-module__date-picker__wrapper___tNp4l:focus {
  border: 1px solid var(--color-action-default);
  border-radius: 4px;
  outline: none;
  transition: border-color .2s ease-in-out;
}

.lc-DatePicker-module__date-picker__months___QuPb0 {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.lc-DatePicker-module__date-picker__month___239nh {
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  display: table;
}

.lc-DatePicker-module__date-picker__nav-bar___u-l17 {
  justify-content: space-between;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

.lc-DatePicker-module__date-picker__nav-button___1Ysdp {
  cursor: pointer;
  color: var(--content-basic-secondary);
  background-color: #0000;
  border: 1px solid #0000;
  align-content: center;
  padding: 0;
  transition: border-color .2s ease-in-out;
  display: flex;
}

.lc-DatePicker-module__date-picker__nav-button___1Ysdp:hover, .lc-DatePicker-module__date-picker__nav-button___1Ysdp:focus {
  outline: none;
}

.lc-DatePicker-module__date-picker__nav-button___1Ysdp:hover svg, .lc-DatePicker-module__date-picker__nav-button___1Ysdp:focus svg {
  color: var(--content-basic-primary);
}

.lc-DatePicker-module__date-picker__nav-button--interaction-disabled___Ivjrx {
  pointer-events: none;
  display: none;
}

.lc-DatePicker-module__date-picker__caption___XPgZJ {
  margin-bottom: 12px;
  padding: 0 50px;
  display: table-caption;
}

.lc-DatePicker-module__date-picker__caption___XPgZJ > div {
  text-align: center;
  color: var(--content-basic-primary);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.lc-DatePicker-module__date-picker__weekdays___kpTY9 {
  display: table-header-group;
}

.lc-DatePicker-module__date-picker__weekdays-row___tGgay {
  display: table-row;
}

.lc-DatePicker-module__date-picker__weekday___3FCx8 {
  text-align: center;
  letter-spacing: .2px;
  color: var(--content-basic-secondary);
  margin-bottom: 2px;
  padding: 6px 0;
  font-size: 12px;
  line-height: 16px;
  display: table-cell;
}

.lc-DatePicker-module__date-picker__weekday___3FCx8 abbr[title] {
  border-bottom: initial;
  text-decoration: none;
}

.lc-DatePicker-module__date-picker__body___toP1u {
  display: table-row-group;
}

.lc-DatePicker-module__date-picker__week___nZI1x {
  display: table-row;
}

.lc-DatePicker-module__date-picker__day___ZTSw9 {
  cursor: pointer;
  color: var(--content-basic-primary);
  width: 18px;
  height: 18px;
  font-size: 14px;
  display: table-cell;
}

.lc-DatePicker-module__date-picker__day___ZTSw9:focus {
  outline: none;
}

.lc-DatePicker-module__date-picker__day___ZTSw9:focus .lc-DatePicker-module__date-picker__day-content___rDIa0 {
  box-shadow: 0 0 0 1px var(--border-basic-primary);
  border-radius: 2px;
}

.lc-DatePicker-module__date-picker__day-wrapper___gGeeJ {
  margin: 1px 0;
  padding: 0 2px;
}

.lc-DatePicker-module__date-picker__day-content___rDIa0 {
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  display: flex;
}

.lc-DatePicker-module__date-picker__day--today___LL51c .lc-DatePicker-module__date-picker__day-content___rDIa0 {
  box-shadow: 0 0 0 1px var(--border-basic-primary);
  background-color: var(--surface-primary-default);
  border-radius: 2px;
}

.lc-DatePicker-module__date-picker__day--selected___1D1tC .lc-DatePicker-module__date-picker__day-content___rDIa0 {
  background-color: var(--action-primary-default);
  color: var(--content-invert-primary);
  border-radius: 2px;
}

.lc-DatePicker-module__date-picker__day--outside___ptNdC {
  cursor: default;
  color: var(--content-basic-disabled);
}

.lc-DatePicker-module__date-picker__day--disabled___4O6yW {
  cursor: default;
  color: var(--content-basic-disabled);
  pointer-events: none;
}

.lc-DatePicker-module__date-picker--interaction-disabled___-zn8o .lc-DatePicker-module__date-picker__day___ZTSw9 {
  cursor: default;
  pointer-events: none;
}

.lc-DatePicker-module__date-picker__footer___cXMQ5 {
  padding-top: 5px;
}

.lc-DatePicker-module__date-picker__input___r6qih {
  display: inline-block;
}

.lc-DatePicker-module__date-picker__input-overlay-wrapper___vmC0M {
  position: relative;
}

.lc-DatePicker-module__date-picker__input-overlay___eJ9D0 {
  display: block;
}

.lc-DatePicker-module__date-picker--range___ZU-7k .lc-DatePicker-module__date-picker__day--selected___1D1tC:not(.lc-DatePicker-module__date-picker__day--disabled___4O6yW):not(.lc-DatePicker-module__date-picker__day--outside___ptNdC):not(.lc-DatePicker-module__date-picker__day--start___-oB8U):not(.lc-DatePicker-module__date-picker__day--end___TtN5P):not(.lc-DatePicker-module__date-picker__day--single___biWcB):not(.lc-DatePicker-module__date-picker__day--sunday___JEnhV):not(.lc-DatePicker-module__date-picker__day--monday___NXYIr) .lc-DatePicker-module__date-picker__day-wrapper___gGeeJ {
  background-color: var(--surface-accent-emphasis-low-info);
}

.lc-DatePicker-module__date-picker--range___ZU-7k .lc-DatePicker-module__date-picker__day--selected___1D1tC .lc-DatePicker-module__date-picker__day-content___rDIa0 {
  color: var(--content-default);
  background-color: #0000;
  border-radius: 0;
}

.lc-DatePicker-module__date-picker--range___ZU-7k .lc-DatePicker-module__date-picker__day--start___-oB8U:not(.lc-DatePicker-module__date-picker__day--end___TtN5P):not(.lc-DatePicker-module__date-picker__day--sunday___JEnhV):not(.lc-DatePicker-module__date-picker__day--monday___NXYIr) .lc-DatePicker-module__date-picker__day-wrapper___gGeeJ, .lc-DatePicker-module__date-picker--range___ZU-7k .lc-DatePicker-module__date-picker__day--monday___NXYIr.lc-DatePicker-module__date-picker__day--selected___1D1tC:not(.lc-DatePicker-module__date-picker__day--end___TtN5P) .lc-DatePicker-module__date-picker__day-wrapper___gGeeJ {
  background: linear-gradient(to left, var(--surface-accent-emphasis-low-info) 0%, var(--surface-accent-emphasis-low-info) 50%, var(--surface-primary-default) 50%, var(--surface-primary-default) 100%);
}

.lc-DatePicker-module__date-picker--range___ZU-7k .lc-DatePicker-module__date-picker__day--end___TtN5P:not(.lc-DatePicker-module__date-picker__day--start___-oB8U):not(.lc-DatePicker-module__date-picker__day--monday___NXYIr):not(.lc-DatePicker-module__date-picker__day--sunday___JEnhV) .lc-DatePicker-module__date-picker__day-wrapper___gGeeJ, .lc-DatePicker-module__date-picker--range___ZU-7k .lc-DatePicker-module__date-picker__day--sunday___JEnhV.lc-DatePicker-module__date-picker__day--selected___1D1tC:not(.lc-DatePicker-module__date-picker__day--start___-oB8U) .lc-DatePicker-module__date-picker__day-wrapper___gGeeJ {
  background: linear-gradient(to right, var(--surface-accent-emphasis-low-info) 0%, var(--surface-accent-emphasis-low-info) 50%, var(--surface-primary-default) 50%, var(--surface-primary-default) 100%);
}

.lc-DatePicker-module__date-picker--range___ZU-7k .lc-DatePicker-module__date-picker__day--monday___NXYIr.lc-DatePicker-module__date-picker__day--selected___1D1tC:not(.lc-DatePicker-module__date-picker__day--start___-oB8U):not(.lc-DatePicker-module__date-picker__day--end___TtN5P) .lc-DatePicker-module__date-picker__day-content___rDIa0, .lc-DatePicker-module__date-picker--range___ZU-7k .lc-DatePicker-module__date-picker__day--sunday___JEnhV.lc-DatePicker-module__date-picker__day--selected___1D1tC:not(.lc-DatePicker-module__date-picker__day--start___-oB8U):not(.lc-DatePicker-module__date-picker__day--end___TtN5P) .lc-DatePicker-module__date-picker__day-content___rDIa0 {
  background-color: var(--surface-accent-emphasis-low-info);
}

.lc-DatePicker-module__date-picker--range___ZU-7k .lc-DatePicker-module__date-picker__day--start___-oB8U .lc-DatePicker-module__date-picker__day-content___rDIa0, .lc-DatePicker-module__date-picker--range___ZU-7k .lc-DatePicker-module__date-picker__day--end___TtN5P .lc-DatePicker-module__date-picker__day-content___rDIa0 {
  background-color: var(--action-primary-default);
  color: var(--content-invert-primary);
  border-radius: 2px;
}

.lc-DatePicker-module__date-picker--range__select-input___6pyj3 {
  text-align: left;
  border-color: #0000;
  max-width: 90px;
  padding: 0 5px;
}

.lc-DatePicker-module__date-picker--range__calendars-wrapper___8nWaW {
  align-items: flex-start;
  display: flex;
}

.lc-DatePicker-module__date-picker--range__calendars-wrapper___8nWaW .lc-DatePicker-module__date-picker__months___QuPb0 {
  flex-wrap: nowrap;
  margin: 0 -12px;
}

.lc-DatePicker-module__date-picker--range__calendars-wrapper___8nWaW .lc-DatePicker-module__date-picker__months___QuPb0:before {
  background-color: var(--border-basic-tertiary);
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
}

.lc-DatePicker-module__date-picker--range__calendars-wrapper___8nWaW .lc-DatePicker-module__date-picker__month___239nh {
  padding: 0 12px;
}

.lc-DatePicker-module__date-picker___9AqJ2 .lc-DatePicker-module__date-picker__navbar-buttons-wrapper___Hnksb {
  gap: 4px;
  display: flex;
}

.lc-FieldError-module__field-error___IDkPT {
  margin: var(--spacing-1) 0 0;
  color: var(--content-basic-negative);
  display: flex;
}

.lc-FieldError-module__field-error__icon___D4UNC {
  margin-right: var(--spacing-1);
}

.lc-FieldGroup-module__field-group___gy8lp {
  flex-direction: column;
  align-items: flex-start;
  margin: -8px 0;
  display: flex;
}

.lc-FieldGroup-module__field-group___gy8lp > * {
  padding: 0 0 8px;
}

.lc-FieldGroup-module__field-group--inline___or4qf {
  flex-direction: row;
  margin: 0 -8px;
}

.lc-FieldGroup-module__field-group--inline___or4qf > * {
  padding: 0 8px;
}

.lc-FieldGroup-module__field-group--stretched___6rkuO > * {
  width: 100%;
}

.lc-Form-module__form___1nOYF {
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
}

.lc-Form-module__form__header___U4B7t, .lc-Form-module__form__footer___1-sUX {
  margin-bottom: 32px;
}

.lc-Form-module__form__label___spg-J {
  color: var(--content-basic-primary);
  margin: 0 0 16px;
}

.lc-Form-module__form__helper___bhfwS {
  color: var(--content-basic-primary);
  margin: 0;
}

.lc-FormField-module__form-field___JqnX6 {
  margin-bottom: var(--spacing-5);
  display: inline-block;
}

.lc-FormField-module__form-field--inline___4DgIq {
  flex-direction: column;
  display: flex;
}

.lc-FormField-module__form-field__wrapper--inline___Ubvrn {
  flex-flow: row;
  display: flex;
}

.lc-FormField-module__form-field__label___hNCFS {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;
  display: flex;
}

.lc-FormField-module__form-field__label___hNCFS label {
  margin-bottom: 0;
}

.lc-FormField-module__form-field__label--inline___QxyM6 {
  margin-right: var(--spacing-2);
  flex-shrink: 0;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 0;
}

.lc-FormField-module__form-field__label--no-text___Ohs-I {
  justify-content: flex-end;
  margin-right: 0;
}

.lc-FormField-module__form-field__label-wrapper___61-NO {
  flex-grow: 1;
  display: flex;
}

.lc-FormField-module__form-field__label-wrapper--inline___YVYza {
  align-items: center;
}

.lc-FormField-module__form-field__label-adornment___HB0z7 {
  flex-shrink: 0;
  justify-content: flex-end;
  align-self: flex-end;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-left: 4px;
  line-height: 20px;
  display: flex;
  overflow: hidden;
}

.lc-FormField-module__form-field__label-adornment--inline___pwRXm {
  align-self: center;
}

.lc-FormField-module__form-field__label-left-node___dvipB {
  color: var(--content-basic-primary);
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 20px;
  display: block;
}

.lc-FormField-module__form-field__label-right-node___LR-03 {
  color: var(--content-basic-primary);
  flex-shrink: 0;
  justify-content: flex-end;
  align-self: flex-end;
  margin-left: 5px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
}

.lc-FormField-module__form-field__label-right-node--inline___BkiPG {
  flex-grow: 1;
  margin-bottom: 4px;
}

.lc-FormField-module__form-field__row-break___DCBjC {
  flex-basis: 100%;
  height: 0;
}

.lc-FormField-module__form-field__content___XaOx3 {
  flex-grow: 1;
}

.lc-FormField-module__form-field__content__description___NpVw4 {
  margin-top: var(--spacing-1);
}

.lc-FormGroup-module__form-group___e2JHa {
  border-style: none;
  min-width: auto;
  margin: 0 0 32px;
  padding: 0;
}

.lc-FormGroup-module__form-group__header___DgGmR {
  margin-bottom: 16px;
}

.lc-FormGroup-module__form-group__label___NC-JW {
  color: var(--content-basic-primary);
  margin-bottom: 4px;
  display: block;
}

.lc-FormGroup-module__form-group__helper___SRuxe {
  color: var(--content-basic-secondary);
}

.lc-Input-module__input___qeMAQ {
  box-sizing: border-box;
  border: 1px solid var(--border-basic-primary);
  border-radius: var(--radius-3);
  background: var(--surface-primary-default);
  color: var(--content-basic-primary);
  outline: none;
  align-items: center;
  padding: 5px 12px;
  font-size: 15px;
  line-height: 22px;
  display: flex;
}

.lc-Input-module__input___qeMAQ::placeholder {
  color: var(--content-basic-disabled);
}

.lc-Input-module__input___qeMAQ:hover {
  border-color: var(--border-basic-hover);
}

.lc-Input-module__input___qeMAQ:focus {
  border-color: var(--action-primary-default);
}

.lc-Input-module__input___qeMAQ:disabled {
  border-color: var(--border-basic-disabled);
  background-color: var(--surface-primary-disabled);
  color: var(--content-basic-disabled);
}

.lc-Input-module__input--focused___wQH5-, .lc-Input-module__input--focused___wQH5-:hover {
  border-color: var(--action-primary-default);
}

.lc-Input-module__input--disabled___HidXX, .lc-Input-module__input--disabled___HidXX:hover {
  border-color: var(--border-basic-disabled);
  background-color: var(--surface-primary-disabled);
}

.lc-Input-module__input___qeMAQ input {
  color: var(--content-basic-primary);
  background: none;
  border: 0;
  outline: none;
  width: 100%;
  padding: 0;
}

.lc-Input-module__input--crop___uzJS8 input {
  text-overflow: ellipsis;
}

.lc-Input-module__input--error___DlDbC, .lc-Input-module__input--error___DlDbC:hover {
  border-color: var(--border-basic-negative);
}

.lc-Input-module__input--xsmall___9u99K {
  height: 24px;
}

.lc-Input-module__input--compact___iKSQZ {
  height: 32px;
}

.lc-Input-module__input--medium___3BjqQ {
  height: 36px;
}

.lc-Input-module__input--large___jX5RW {
  height: 44px;
}

.lc-Input-module__input__icon--disabled___XO3dc {
  color: var(--content-basic-disabled);
}

.lc-Input-module__input__icon--left___BcySs {
  margin-right: 8px;
}

.lc-Input-module__input__icon--right___VefSK, .lc-Input-module__input__visibility-button___eWrVe {
  margin-left: 8px;
}

.lc-Link-module__link___kqx52 {
  cursor: pointer;
  color: var(--action-primary-default);
  text-decoration: none;
}

.lc-Link-module__link___kqx52:hover {
  color: var(--action-primary-hover);
  text-decoration: underline;
}

.lc-Link-module__link___kqx52:active {
  color: var(--action-primary-active);
}

.lc-Link-module__link--bold___1rGdO {
  font-weight: 600;
}

.lc-Modal-module__modal-base___hbL1U {
  border-radius: var(--radius-3);
  background-color: var(--surface-primary-default);
  padding: var(--spacing-6) var(--spacing-7);
  color: var(--content-basic-primary);
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
  margin: 10px;
  display: flex;
  position: relative;
  box-shadow: 0 20px 60px #0000004d;
}

.lc-Modal-module__modal-base--full-space___KcGFL {
  padding: 0;
}

.lc-Modal-module__modal-base__overlay___kCQ8t {
  z-index: 13000;
  background-color: var(--surface-other-overlay);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.lc-Modal-module__modal-base__overlay--visible___zUNJL {
  display: flex;
}

.lc-Modal-module__modal-base__close___gJlgr {
  z-index: 1;
  box-shadow: none;
  cursor: pointer;
  color: var(--content-basic-secondary);
  background-color: #0000;
  border-style: none;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
}

.lc-Modal-module__modal-base__close___gJlgr:hover {
  color: var(--content-basic-primary);
}

.lc-Modal-module__modal-base__close___gJlgr:active, .lc-Modal-module__modal-base__close___gJlgr:focus {
  border: 1px solid var(--action-primary-default);
}

.lc-Modal-module__modal-base__close--label-type___Om0Vb {
  position: initial;
  color: var(--content-invert-primary);
}

.lc-Modal-module__modal-base__close--label-type___Om0Vb:hover, .lc-Modal-module__modal-base__close--label-type___Om0Vb:focus, .lc-Modal-module__modal-base__close--label-type___Om0Vb:active {
  background-color: #0000;
}

.lc-Modal-module__modal__body___M-jmN {
  box-sizing: border-box;
  color: var(--content-basic-primary);
  flex-direction: column;
  flex: 1;
  width: 100%;
  display: flex;
}

.lc-Modal-module__modal__header___Fp5VE {
  box-sizing: border-box;
  padding-bottom: var(--spacing-5);
  color: var(--content-basic-primary);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.lc-Modal-module__modal__label-header___ZPtEy {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  display: flex;
  position: absolute;
  top: -52px;
  left: 0;
}

.lc-Modal-module__modal__heading___G9KVK {
  text-align: left;
  color: var(--content-basic-primary);
  max-width: calc(100% - 32px);
  margin: 0;
  padding: 0;
}

.lc-Modal-module__modal__label-heading___WnYcF {
  color: var(--color-white);
}

.lc-Modal-module__modal__footer___2LYdc {
  box-sizing: border-box;
  padding-top: var(--spacing-5);
  justify-content: flex-end;
  width: 100%;
  display: flex;
}

.lc-ModalHeader-module__modal-header___xk3bd {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.lc-ModalHeader-module__modal-header__heading-left-node___1--G5 {
  margin-right: 12px;
}

.lc-ModalHeader-module__modal-header__heading-body___HHEF- {
  flex-direction: column;
  display: flex;
}

.lc-ModalHeader-module__modal-header__heading-title___2R9pm {
  font-size: 18px;
  line-height: 24px;
}

.lc-ModalHeader-module__modal-header__heading-description___54Xvf {
  font-size: 14px;
  line-height: 20px;
}

.lc-NumericInput-module__numeric-input___j1esc {
  width: 64px;
  height: 36px;
  display: inline-block;
  position: relative;
}

.lc-NumericInput-module__numeric-input__increment___i3Tys, .lc-NumericInput-module__numeric-input__decrement___3qgn0 {
  box-sizing: border-box;
  border: 0;
  border-left: 1px solid var(--border-basic-primary);
  cursor: pointer;
  background: none;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 50%;
  padding: 0;
  display: flex;
  position: absolute;
  top: 1px;
  right: 1px;
}

.lc-NumericInput-module__numeric-input__increment___i3Tys:hover, .lc-NumericInput-module__numeric-input__increment___i3Tys:focus, .lc-NumericInput-module__numeric-input__decrement___3qgn0:hover, .lc-NumericInput-module__numeric-input__decrement___3qgn0:focus {
  outline: 0;
}

.lc-NumericInput-module__numeric-input___j1esc .lc-NumericInput-module__numeric-input__increment___i3Tys:disabled {
  border-color: var(--border-basic-disabled);
  cursor: not-allowed;
}

.lc-NumericInput-module__numeric-input___j1esc .lc-NumericInput-module__numeric-input__decrement___3qgn0 {
  border-top: 1px solid var(--border-basic-primary);
  top: auto;
  bottom: 1px;
}

.lc-NumericInput-module__numeric-input___j1esc .lc-NumericInput-module__numeric-input__decrement___3qgn0:disabled {
  border-color: var(--border-basic-disabled);
  cursor: not-allowed;
}

.lc-NumericInput-module__numeric-input___j1esc input {
  -webkit-appearance: textfield;
  appearance: textfield;
  box-sizing: border-box;
  border: 1px solid var(--border-basic-primary);
  border-radius: var(--radius-3);
  background-clip: padding-box;
  background-color: var(--surface-primary-default);
  text-align: right;
  color: var(--content-basic-primary);
  will-change: border-color;
  width: 100%;
  height: 100%;
  padding: 0 28px 0 4px;
  line-height: 36px;
  transition: border .25s ease-in-out;
}

.lc-NumericInput-module__numeric-input___j1esc input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.lc-NumericInput-module__numeric-input___j1esc input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.lc-NumericInput-module__numeric-input___j1esc input:hover {
  border-color: var(--border-basic-hover);
  outline: 0;
}

.lc-NumericInput-module__numeric-input___j1esc input:focus {
  border-color: var(--action-primary-default);
  outline: 0;
}

.lc-NumericInput-module__numeric-input___j1esc input:disabled {
  cursor: not-allowed;
  color: var(--content-basic-disabled);
}

.lc-NumericInput-module__numeric-input___j1esc input:disabled:hover {
  border-color: var(--border-basic-disabled);
}

.lc-NumericInput-module__numeric-input--disabled___yaRvQ {
  background-color: var(--surface-primary-disabled);
  cursor: not-allowed;
  color: var(--content-basic-primary);
}

.lc-NumericInput-module__numeric-input___j1esc.lc-NumericInput-module__numeric-input--no-controls___D90rG input {
  padding: 0 4px;
}

.lc-NumericInput-module__numeric-input___j1esc.lc-NumericInput-module__numeric-input--error___TMxRx input, .lc-NumericInput-module__numeric-input___j1esc.lc-NumericInput-module__numeric-input--error___TMxRx input:hover, .lc-NumericInput-module__numeric-input___j1esc.lc-NumericInput-module__numeric-input--error___TMxRx input:focus {
  border-color: var(--border-basic-negative);
}

.lc-PickerList-module__list-wrapper___ZJjPY {
  z-index: 2000;
  border-radius: 200px;
  border-radius: var(--radius-3);
  box-shadow: var(--shadow-pop-over);
  width: 100%;
  position: absolute;
  top: calc(100% + 4px);
  overflow: auto;
}

.lc-PickerList-module__picker-list___FqdgZ {
  box-sizing: border-box;
  gap: var(--spacing-05);
  background-color: var(--picker-list-background);
  padding: var(--spacing-2);
  color-scheme: var(--color-scheme);
  flex-direction: column;
  max-height: 242px;
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  overflow: auto;
}

.lc-PickerList-module__picker-list__no-results___tX172 {
  justify-content: center;
  align-items: center;
  height: 36px;
  display: flex;
}

.lc-PickerList-module__picker-list__item___8QNS0 {
  box-sizing: border-box;
  border-radius: var(--radius-3);
  cursor: pointer;
  border: 1px solid #0000;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 6px 11px;
  display: flex;
  position: relative;
}

.lc-PickerList-module__picker-list__item___8QNS0:hover {
  background-color: var(--picker-list-option-background-hover);
}

.lc-PickerList-module__picker-list__item___8QNS0:focus, .lc-PickerList-module__picker-list__item___8QNS0:active {
  background-color: var(--picker-list-option-background-focus);
}

.lc-PickerList-module__picker-list__item___8QNS0[aria-current="true"] {
  border-color: var(--action-primary-default);
  background-color: var(--picker-list-option-background-focus);
}

.lc-PickerList-module__picker-list__item___8QNS0[aria-disabled="true"] {
  color: var(--content-basic-disabled);
}

.lc-PickerList-module__picker-list__item___8QNS0[aria-disabled="true"]:hover {
  background-color: unset;
  cursor: auto;
}

.lc-PickerList-module__picker-list__item___8QNS0[aria-selected="true"] {
  background-color: var(--picker-list-option-background-active);
}

.lc-PickerList-module__picker-list__item___8QNS0[aria-selected="true"]:after {
  border-top-right-radius: var(--radius-3);
  border-bottom-right-radius: var(--radius-3);
  background: var(--action-primary-default);
  content: "";
  width: 3px;
  height: 100%;
  position: absolute;
  left: -9px;
}

.lc-PickerList-module__picker-list__item___8QNS0[aria-selected="true"]:hover {
  background-color: var(--picker-list-option-background-active-hover);
}

.lc-PickerList-module__picker-list__item___8QNS0[aria-selected="true"][data-adjacent="top"]:after {
  height: 120%;
  top: 0;
}

.lc-PickerList-module__picker-list__item___8QNS0[aria-selected="true"][data-adjacent="middle"]:after {
  height: 120%;
}

.lc-PickerList-module__picker-list__item___8QNS0[aria-selected="true"][data-adjacent="bottom"]:after {
  height: 120%;
  bottom: 0;
}

.lc-PickerList-module__picker-list__item--select-all___PlTHV {
  border-bottom: 1px solid var(--border-basic-tertiary);
}

.lc-PickerList-module__picker-list__item__header___mxmg0 {
  z-index: 1;
  background-color: var(--picker-list-group-background);
  cursor: auto;
  text-transform: uppercase;
  color: var(--content-basic-secondary);
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding: 12px 12px 4px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  position: relative;
}

.lc-PickerList-module__picker-list__item__header___mxmg0:after {
  z-index: -1;
  background: var(--picker-list-group-background);
  content: "";
  height: 36px;
  position: absolute;
  top: 0;
  left: -8px;
  right: -8px;
}

.lc-PickerList-module__picker-list__item__header___mxmg0:first-of-type:before {
  top: calc(var(--spacing-2) * -1);
  z-index: -1;
  background: var(--picker-list-group-background);
  height: var(--spacing-2);
  content: "";
  position: absolute;
  left: -8px;
  right: -8px;
}

.lc-PickerList-module__picker-list__item__content___D-tsE {
  flex: 1;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.lc-PickerList-module__picker-list__item__custom___kJEP6 {
  width: 100%;
  height: auto;
}

.lc-PickerList-module__picker-list__item__checkbox___WFuqO {
  margin-right: var(--spacing-2);
  align-self: center;
}

.lc-PickerList-module__picker-list__item__avatar___hZjIP, .lc-PickerList-module__picker-list__item__icon___BYspu {
  margin-right: var(--spacing-2);
  border-radius: var(--radius-3);
  color: var(--content-basic-primary);
  align-self: center;
  width: 20px;
  height: 20px;
}

.lc-PickerList-module__picker-list__item__label-container___yZXW5 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

.lc-PickerList-module__picker-list__item__main-label___uRgQw {
  font-weight: 700;
}

.lc-PickerList-module__picker-list__item__secondary-label___lpkFW {
  color: var(--content-basic-secondary);
  font-size: 12px;
}

.lc-Trigger-module__picker-trigger___BIvTx {
  box-sizing: border-box;
  border: 1px solid var(--border-basic-primary);
  border-radius: var(--radius-3);
  background-color: var(--surface-primary-default);
  padding: 0 var(--spacing-3);
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  transition: border .15s ease-in-out;
  display: flex;
  position: relative;
}

.lc-Trigger-module__picker-trigger___BIvTx:hover {
  border-color: var(--border-basic-hover);
  cursor: pointer;
}

.lc-Trigger-module__picker-trigger___BIvTx:focus, .lc-Trigger-module__picker-trigger___BIvTx:active {
  border-color: var(--action-primary-default);
  outline: 0;
}

.lc-Trigger-module__picker-trigger--multi-select___rLpTy {
  overflow: hidden;
}

.lc-Trigger-module__picker-trigger--multi-select--with-items___QFhY2 {
  padding: 0 var(--spacing-3) 0 5px;
}

.lc-Trigger-module__picker-trigger__controls___EsSeM {
  align-items: center;
  display: flex;
}

.lc-Trigger-module__picker-trigger__controls--compact___g3P6e {
  height: 30px;
}

.lc-Trigger-module__picker-trigger__controls--medium___G4OYQ {
  height: 34px;
}

.lc-Trigger-module__picker-trigger__controls--large___h-egq {
  height: 42px;
}

.lc-Trigger-module__picker-trigger__clear-icon___8H-4T {
  background-color: var(--surface-moderate-default);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.lc-Trigger-module__picker-trigger__content___bYnCH {
  flex-grow: 2;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.lc-Trigger-module__picker-trigger__content--compact___hoBbY {
  height: 30px;
}

.lc-Trigger-module__picker-trigger__content--medium___Grjol {
  height: 34px;
}

.lc-Trigger-module__picker-trigger__content--large___hJB9C {
  height: 42px;
}

.lc-Trigger-module__picker-trigger__content--with-items___IKMsm {
  align-self: center;
  height: auto;
  padding: 5px 5px 5px 0;
}

.lc-Trigger-module__picker-trigger--disabled___7H4tD {
  background-color: var(--surface-basic-disabled);
  color: var(--content-basic-disabled);
}

.lc-Trigger-module__picker-trigger--disabled___7H4tD:hover {
  border-color: var(--border-basic-primary);
  cursor: auto;
}

.lc-Trigger-module__picker-trigger--focused___vTr39, .lc-Trigger-module__picker-trigger--focused___vTr39:hover {
  border-color: var(--action-primary-default);
}

.lc-Trigger-module__picker-trigger--error___Rh8Qd, .lc-Trigger-module__picker-trigger--error___Rh8Qd:hover {
  border-color: var(--action-negative-default);
}

.lc-Tag-module__tag___1QRVY {
  box-sizing: border-box;
  border-radius: var(--radius-3);
  border-color: var(--border-basic-primary);
  background-color: var(--surface-tertiary-default);
  color: var(--content-basic-primary);
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.lc-Tag-module__tag--success___P2hXQ {
  border-color: var(--border-basic-positive);
  background-color: var(--surface-accent-emphasis-low-positive);
  color: var(--content-basic-positive);
}

.lc-Tag-module__tag--success___P2hXQ .lc-Tag-module__tag__remove___UmtrW {
  color: var(--content-basic-positive);
}

.lc-Tag-module__tag--error___1cjsm {
  border-color: var(--border-basic-negative);
  background-color: var(--surface-accent-emphasis-low-negative);
  color: var(--content-basic-negative);
}

.lc-Tag-module__tag--error___1cjsm .lc-Tag-module__tag__remove___UmtrW {
  color: var(--content-basic-negative);
}

.lc-Tag-module__tag--warning___PstMG {
  border-color: var(--border-basic-warning);
  background-color: var(--surface-accent-emphasis-low-warning);
  color: var(--content-basic-warning);
}

.lc-Tag-module__tag--warning___PstMG .lc-Tag-module__tag__remove___UmtrW {
  color: var(--content-basic-warning);
}

.lc-Tag-module__tag--info___RTH0C {
  border-color: var(--border-basic-info);
  background-color: var(--surface-accent-emphasis-low-info);
  color: var(--content-basic-info);
}

.lc-Tag-module__tag--info___RTH0C .lc-Tag-module__tag__remove___UmtrW {
  color: var(--content-basic-info);
}

.lc-Tag-module__tag--purple___a0xaE {
  border-color: var(--border-basic-purple);
  background-color: var(--surface-accent-emphasis-low-purple);
  color: var(--content-basic-purple);
}

.lc-Tag-module__tag--purple___a0xaE .lc-Tag-module__tag__remove___UmtrW {
  color: var(--content-basic-purple);
}

.lc-Tag-module__tag--black___qWjfD {
  border-color: var(--content-basic-primary);
  background-color: var(--surface-invert-primary);
  color: var(--content-invert-primary);
}

.lc-Tag-module__tag--black___qWjfD .lc-Tag-module__tag__remove___UmtrW {
  color: var(--content-invert-primary);
}

.lc-Tag-module__tag--black___qWjfD.lc-Tag-module__tag--outline___aZXtr, .lc-Tag-module__tag--black___qWjfD.lc-Tag-module__tag--outline___aZXtr .lc-Tag-module__tag__remove___UmtrW {
  color: var(--content-basic-primary);
}

.lc-Tag-module__tag--small___xfcC5 {
  padding: 1px var(--spacing-1);
  height: 20px;
}

.lc-Tag-module__tag--medium___l5-KI {
  padding: 1px var(--spacing-1);
  height: 24px;
}

.lc-Tag-module__tag--large___ytC6Y {
  padding: 5px var(--spacing-2);
  height: 32px;
}

.lc-Tag-module__tag--xlarge___2jj1d {
  padding: 5px var(--spacing-3);
  height: 36px;
}

.lc-Tag-module__tag--outline___aZXtr {
  background-color: #0000;
  border-style: solid;
  border-width: 1px;
}

.lc-Tag-module__tag--outline___aZXtr.lc-Tag-module__tag--small___xfcC5, .lc-Tag-module__tag--outline___aZXtr.lc-Tag-module__tag--medium___l5-KI {
  padding: 0 calc(var(--spacing-1)  - 1px);
}

.lc-Tag-module__tag--outline___aZXtr.lc-Tag-module__tag--large___ytC6Y {
  padding: 4px calc(var(--spacing-2)  - 1px);
}

.lc-Tag-module__tag--outline___aZXtr.lc-Tag-module__tag--xlarge___2jj1d {
  padding: 4px calc(var(--spacing-3)  - 1px);
}

.lc-Tag-module__tag--outline___aZXtr .lc-Tag-module__icon___2Z-35 {
  background-color: #0000;
}

.lc-Tag-module__tag--text-white___x0fnD {
  color: var(--color-white);
}

.lc-Tag-module__tag--text-black___dUvmD {
  color: var(--color-black);
}

.lc-Tag-module__tag--dismissible___Qobyr.lc-Tag-module__tag--medium___l5-KI {
  padding-right: var(--spacing-1);
}

.lc-Tag-module__tag--dismissible___Qobyr.lc-Tag-module__tag--large___ytC6Y {
  padding-right: var(--spacing-2);
}

.lc-Tag-module__tag__node___rZTzB {
  align-items: center;
  display: flex;
}

.lc-Tag-module__tag__remove___UmtrW {
  box-shadow: none;
  cursor: pointer;
  padding: 0 0 0 var(--spacing-1);
  color: var(--content-basic-secondary);
  background-color: #0000;
  border-style: none;
  outline: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.lc-Tag-module__tag__content___q6vKK {
  margin-right: var(--spacing-1);
  margin-left: var(--spacing-1);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.lc-TriggerBody-module__picker-trigger-body___ZWjX9 {
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 4px;
  max-width: 100%;
  display: flex;
}

.lc-TriggerBody-module__picker-trigger-body--single___KNWtO {
  flex-wrap: nowrap;
}

.lc-TriggerBody-module__picker-trigger-body__item-container___l2NTQ {
  color-scheme: var(--color-scheme);
  flex-wrap: wrap;
  gap: 4px;
  max-height: 96px;
  display: flex;
  overflow-y: auto;
}

.lc-TriggerBody-module__picker-trigger-body__item___0DBg1 {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.lc-TriggerBody-module__picker-trigger-body__item__content___VhhV- {
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.lc-TriggerBody-module__picker-trigger-body__item__avatar___TuCJ-, .lc-TriggerBody-module__picker-trigger-body__item__icon___ZxGd8 {
  margin-right: var(--spacing-2);
  border-radius: 50%;
  align-self: center;
  width: 20px;
  height: 20px;
}

.lc-TriggerBody-module__picker-trigger-body__input___ezPyz {
  color: var(--content-basic-primary);
  background: none;
  border: 0;
  outline: none;
  flex-grow: 2;
  align-items: center;
  height: 22px;
  padding: 0;
  font-size: 15px;
  line-height: 22px;
  display: flex;
}

.lc-TriggerBody-module__picker-trigger-body__input--compact___YKrau {
  height: 20px;
}

.lc-TriggerBody-module__picker-trigger-body__input--medium___euht0 {
  height: 24px;
}

.lc-TriggerBody-module__picker-trigger-body__input--large___3nzb8 {
  height: 32px;
}

.lc-TriggerBody-module__picker-trigger-body__tag___vbOJZ {
  border-radius: var(--radius-1);
  max-width: 100%;
}

.lc-TriggerBody-module__picker-trigger-body__tag--compact___X-oAF {
  height: 20px;
}

.lc-TriggerBody-module__picker-trigger-body__tag--medium___Ptkd5 {
  height: 24px;
}

.lc-TriggerBody-module__picker-trigger-body__tag--large___Saoy1 {
  height: 32px;
}

.lc-Picker-module__picker___GRj9W {
  box-sizing: border-box;
  color: var(--content-basic-primary);
  width: 100%;
  display: inline-block;
  position: relative;
}

.lc-Picker-module__picker__container___s0PUc {
  position: relative;
}

.lc-ProgressCircle-module__progress-circle___NRHCi {
  width: 36px;
  height: 36px;
  transform: rotate(-90deg);
}

.lc-ProgressCircle-module__progress-circle--small___8FVwG {
  width: 16px;
  height: 16px;
}

.lc-ProgressCircle-module__progress-circle--medium___XlzEX {
  width: 36px;
  height: 36px;
}

.lc-ProgressCircle-module__progress-circle--large___qxa2y {
  width: 56px;
  height: 56px;
}

.lc-ProgressCircle-module__progress-circle__bg-line--normal___nDNwj, .lc-ProgressCircle-module__progress-circle__bg-line--success___1aP-O {
  stroke: var(--surface-tertiary-default);
}

.lc-ProgressCircle-module__progress-circle__bg-line--error___oqMZL {
  stroke: var(--action-negative-disabled);
}

.lc-ProgressCircle-module__progress-circle__indicator--normal___0SdkX {
  stroke: var(--action-primary-default);
  transition: all .4s cubic-bezier(.08, .82, .17, 1);
}

.lc-ProgressCircle-module__progress-circle__indicator--success___fLwRR {
  stroke: var(--action-positive-default);
  transition: all .4s cubic-bezier(.08, .82, .17, 1);
}

.lc-ProgressCircle-module__progress-circle__indicator--error___nwJS9 {
  transition: all .4s cubic-bezier(.08, .82, .17, 1);
}

.lc-ProgressBar-module__progress-bar___3pa3d {
  background-color: var(--action-primary-disabled);
  border-radius: 100px;
  width: 100%;
  height: 4px;
  display: inline-block;
  position: relative;
}

.lc-ProgressBar-module__progress-bar--small___7nQKx {
  height: 3px;
}

.lc-ProgressBar-module__progress-bar--medium___cM44p {
  height: 4px;
}

.lc-ProgressBar-module__progress-bar--large___UlzNR {
  height: 6px;
}

.lc-ProgressBar-module__progress-bar--error___MulqI {
  background-color: var(--action-negative-disabled);
}

.lc-ProgressBar-module__progress-bar--success___p7nB-, .lc-ProgressBar-module__progress-bar--normal___ZwCb- {
  background-color: var(--surface-tertiary-default);
}

.lc-ProgressBar-module__progress-bar__indicator--success___wRjR3 {
  background-color: var(--action-positive-default);
  border-radius: 100px;
  height: 100%;
  transition: all .4s cubic-bezier(.08, .82, .17, 1);
  position: relative;
}

.lc-ProgressBar-module__progress-bar__indicator--normal___0Uhle {
  background-color: var(--action-primary-default);
  border-radius: 100px;
  height: 100%;
  transition: all .4s cubic-bezier(.08, .82, .17, 1);
  position: relative;
}

.lc-PromoBanner-module__promo-banner___-mGpq {
  box-sizing: border-box;
  border: 1px solid var(--border-basic-secondary);
  border-radius: var(--radius-3);
  background-color: var(--surface-secondary-default);
  padding: var(--spacing-5);
  word-break: break-word;
  color: var(--content-basic-primary);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 180px;
  font-size: 15px;
  display: flex;
  position: relative;
}

.lc-PromoBanner-module__promo-banner___-mGpq b {
  font-weight: 600;
}

.lc-PromoBanner-module__promo-banner__content___kRWar {
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
}

.lc-PromoBanner-module__promo-banner__wrapper___I-8cl {
  flex-direction: column;
  display: flex;
}

.lc-PromoBanner-module__promo-banner__header___ebYHW {
  margin-bottom: var(--spacing-2);
  font-size: 16px;
  font-weight: 600;
}

.lc-PromoBanner-module__promo-banner__img___eaShb {
  object-fit: contain;
  width: 48px;
  height: 48px;
}

.lc-PromoBanner-module__promo-banner__button-text___taYKa {
  margin-right: var(--spacing-6);
}

.lc-PromoBanner-module__promo-banner--light___eJxOR {
  border: solid 1px var(--border-basic-tertiary);
  background-color: var(--surface-primary-default);
}

.lc-PromoBanner-module__promo-banner___-mGpq .lc-PromoBanner-module__promo-banner__img___eaShb {
  margin-right: var(--spacing-5);
}

.lc-PromoBanner-module__promo-banner___-mGpq .lc-PromoBanner-module__promo-banner__wrapper___I-8cl {
  max-width: 640px;
}

.lc-PromoBanner-module__promo-banner___-mGpq .lc-PromoBanner-module__promo-banner__footer___8OjvH {
  margin-top: var(--spacing-3);
  flex-shrink: 0;
  display: flex;
}

.lc-PromoBanner-module__promo-banner--small___7sWLV {
  flex-wrap: wrap;
}

.lc-PromoBanner-module__promo-banner--small___7sWLV .lc-PromoBanner-module__promo-banner__content___kRWar {
  flex-direction: column;
}

.lc-PromoBanner-module__promo-banner--small___7sWLV .lc-PromoBanner-module__promo-banner__img___eaShb {
  margin-bottom: var(--spacing-3);
}

.lc-PromoBanner-module__promo-banner--small___7sWLV .lc-PromoBanner-module__promo-banner__footer___8OjvH {
  margin-top: var(--spacing-3);
  display: flex;
}

.lc-PromoBanner-module__promo-banner--large___pMiCU .lc-PromoBanner-module__promo-banner__img___eaShb {
  margin-right: var(--spacing-5);
}

.lc-PromoBanner-module__promo-banner--large___pMiCU .lc-PromoBanner-module__promo-banner__wrapper___I-8cl {
  min-width: 180px;
  max-width: 720px;
}

.lc-PromoBanner-module__promo-banner--large___pMiCU .lc-PromoBanner-module__promo-banner__footer___8OjvH {
  flex-shrink: 0;
  align-items: center;
  margin: 0 56px;
  display: inline-flex;
}

.lc-PromoBanner-module__promo-banner__close-icon___GowBL {
  box-shadow: none;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  width: 24px;
  height: 24px;
  padding: 0;
  position: absolute;
  top: 12px;
  right: 12px;
  overflow: hidden;
}

.lc-PromoBannerV2-module__main-wrapper___U-dPS {
  container-type: inline-size;
}

.lc-PromoBannerV2-module__promo-banner-v2___HkNxR {
  box-sizing: border-box;
  border-radius: var(--radius-3);
  background-color: var(--surface-accent-emphasis-low-info);
  color: var(--content-basic-primary);
  flex-direction: row;
  display: flex;
}

.lc-PromoBannerV2-module__promo-banner-v2__content___65vEP {
  padding: var(--spacing-8);
  flex-flow: column;
  flex: 0 auto;
  justify-content: center;
  max-width: 720px;
  display: flex;
}

.lc-PromoBannerV2-module__promo-banner-v2__content__cta___II2hK {
  gap: var(--spacing-2);
  margin-top: var(--spacing-4);
  display: flex;
}

.lc-PromoBannerV2-module__promo-banner-v2__content__cta__secondary___17BKS {
  margin-left: var(--spacing-4);
}

.lc-PromoBannerV2-module__promo-banner-v2__additional-content___LpU9B {
  padding-right: var(--spacing-2);
  padding-left: var(--spacing-3);
  flex: auto;
  justify-content: right;
  align-items: flex-end;
  display: flex;
}

.lc-PromoBannerV2-module__promo-banner-v2__additional-content___LpU9B img {
  object-fit: contain;
  max-width: 100%;
  height: auto;
}

.lc-PromoBannerV2-module__promo-banner-v2__close___rOh-U {
  padding-top: var(--spacing-3);
  padding-right: var(--spacing-4);
  margin-left: auto;
}

.lc-PromoBannerV2-module__promo-banner-v2__close__btn___5AZQC {
  width: 20px;
  min-width: 20px;
  height: 20px;
}

@container (width <= 559px) {
  .lc-PromoBannerV2-module__promo-banner-v2___HkNxR {
    flex-direction: column;
  }

  .lc-PromoBannerV2-module__promo-banner-v2___HkNxR .lc-PromoBannerV2-module__promo-banner-v2__content___65vEP {
    padding: var(--spacing-5);
    flex: 0 auto;
    order: 2;
  }

  .lc-PromoBannerV2-module__promo-banner-v2___HkNxR .lc-PromoBannerV2-module__promo-banner-v2__additional-content___LpU9B {
    order: 1;
    justify-content: left;
  }

  .lc-PromoBannerV2-module__promo-banner-v2___HkNxR .lc-PromoBannerV2-module__promo-banner-v2__close___rOh-U {
    padding-top: var(--spacing-3);
    padding-right: var(--spacing-3);
  }
}

.lc-RadioButton-module__radio-button___WaToM {
  flex-flow: column;
  display: flex;
}

.lc-RadioButton-module__radio-button___WaToM:focus .lc-RadioButton-module__radio-button__circle___wimWA {
  border-radius: 20px;
  box-shadow: 0 0 1px 1.6px #4384f5b3;
}

.lc-RadioButton-module__radio-button__label___i7ygg {
  cursor: pointer;
  flex: 0 auto;
  align-items: center;
  width: 100%;
  display: inline-flex;
}

.lc-RadioButton-module__radio-button__circle___wimWA {
  box-sizing: border-box;
  border: 1px solid var(--border-basic-primary);
  background-color: var(--surface-primary-default);
  border-radius: 16px;
  width: 16px;
  height: 16px;
  transition: all .2s;
  position: relative;
}

.lc-RadioButton-module__radio-button__inner-circle___qkRVf {
  opacity: 0;
  background-color: var(--action-primary-default);
  border-radius: 8px;
  width: 8px;
  height: 8px;
  transition: all .2s;
  position: absolute;
  top: 3px;
  left: 3px;
}

.lc-RadioButton-module__radio-button__text___mT8c0 {
  color: var(--content-basic-primary);
  flex: 1 0 auto;
  margin-left: 8px;
}

.lc-RadioButton-module__radio-button__input___7OTAK {
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: 0;
}

.lc-RadioButton-module__radio-button__helper___r8gJJ {
  cursor: default;
  margin-left: 24px;
}

.lc-RadioButton-module__radio-button--selected___s9lqj .lc-RadioButton-module__radio-button__circle___wimWA {
  border-color: var(--action-primary-default);
}

.lc-RadioButton-module__radio-button--selected___s9lqj .lc-RadioButton-module__radio-button__inner-circle___qkRVf {
  opacity: 1;
}

.lc-RadioButton-module__radio-button--selected___s9lqj:hover .lc-RadioButton-module__radio-button__circle___wimWA {
  border-color: var(--action-primary-hover);
}

.lc-RadioButton-module__radio-button--selected___s9lqj:hover .lc-RadioButton-module__radio-button__inner-circle___qkRVf {
  background-color: var(--action-primary-hover);
}

.lc-RadioButton-module__radio-button--disabled___wHSA7:not(.lc-RadioButton-module__radio-button--selected___s9lqj) .lc-RadioButton-module__radio-button__circle___wimWA {
  background-color: var(--surface-primary-disabled);
}

.lc-RadioButton-module__radio-button--disabled___wHSA7 .lc-RadioButton-module__radio-button__circle___wimWA {
  opacity: .4;
}

.lc-RadioButton-module__radio-button--disabled___wHSA7 .lc-RadioButton-module__radio-button__label___i7ygg, .lc-RadioButton-module__radio-button--disabled___wHSA7 .lc-RadioButton-module__radio-button__input___7OTAK, .lc-RadioButton-module__radio-button--disabled___wHSA7 .lc-RadioButton-module__radio-button__text___mT8c0, .lc-RadioButton-module__radio-button--disabled___wHSA7 .lc-RadioButton-module__radio-button__helper___r8gJJ {
  cursor: not-allowed;
  color: var(--content-basic-disabled);
}

.lc-Search-module__search-input___FsbCk {
  box-sizing: border-box;
  border: 1px solid var(--border-basic-primary);
  border-radius: var(--radius-3);
  background-color: var(--surface-primary-default);
  color: var(--content-basic-primary);
  align-items: center;
  width: 100%;
  transition: all .25s ease-in-out;
  display: flex;
  position: relative;
  overflow: hidden;
}

.lc-Search-module__search-input___FsbCk:hover {
  border-color: var(--border-basic-hover);
  cursor: text;
}

.lc-Search-module__search-input--compact___498To {
  height: 32px;
}

.lc-Search-module__search-input--medium___Hwteg {
  height: 36px;
}

.lc-Search-module__search-input--large___-IBuC {
  height: 44px;
}

.lc-Search-module__search-input--focused___i2eul, .lc-Search-module__search-input--focused___i2eul:hover {
  border-color: var(--action-primary-default);
}

.lc-Search-module__search-input--disabled___LSJ2e, .lc-Search-module__search-input--disabled___LSJ2e:hover {
  border-color: var(--border-disabled);
  background-color: var(--surface-basic-disabled);
  cursor: not-allowed;
  color: var(--content-disabled);
}

.lc-Search-module__search-input--collapsable___5GaIH {
  background-color: #0000;
  border-color: #0000;
  width: 42px;
  transition: width .25s ease-in-out, background .25s ease-in-out .25s, border .25s ease-in-out .25s;
}

.lc-Search-module__search-input--collapsable___5GaIH:hover {
  border-color: #0000;
}

.lc-Search-module__search-input--collapsable___5GaIH .lc-Search-module__search-input__search-icon___BBQoD {
  cursor: pointer;
}

.lc-Search-module__search-input--collapsable--open___vqTpD {
  border-color: var(--border-basic-primary);
  background-color: var(--surface-primary-default);
  width: 100%;
  transition: border .25s ease-in-out, background .25s ease-in-out, width .25s ease-in-out .25s;
}

.lc-Search-module__search-input--collapsable--open___vqTpD:hover {
  border-color: var(--border-basic-hover);
}

.lc-Search-module__search-input--collapsable--open___vqTpD.lc-Search-module__search-input--focused___i2eul, .lc-Search-module__search-input--collapsable--open___vqTpD.lc-Search-module__search-input--focused___i2eul:hover {
  border-color: var(--action-primary-default);
}

.lc-Search-module__search-input--collapsable--open___vqTpD .lc-Search-module__search-input__search-icon___BBQoD {
  cursor: text;
  pointer-events: none;
}

.lc-Search-module__search-input__search-icon___BBQoD {
  justify-content: center;
  width: 40px;
  height: 100%;
  position: absolute;
}

.lc-Search-module__search-input__clear-icon___LoNkc {
  margin-right: var(--spacing-1);
}

.lc-Search-module__search-input__loader___pkpIG {
  background-color: var(--surface-primary-default);
  border-radius: 50%;
  align-items: center;
  margin-left: 11px;
  margin-right: 16px;
  padding: 2px;
  display: flex;
}

.lc-Search-module__search-input__input___OhyTr {
  color: inherit;
  background-color: #0000;
  border: 0;
  flex: 1;
  width: 100%;
  padding: 0 0 0 40px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
}

.lc-Search-module__search-input__input___OhyTr::placeholder {
  color: var(--content-basic-disabled);
}

.lc-Search-module__search-input__input___OhyTr:focus {
  outline: none;
}

.lc-Switch-module__switch___AaA2k {
  outline: none;
  display: inline-block;
  position: relative;
}

.lc-Switch-module__switch___AaA2k:hover .lc-Switch-module__switch__track--enabled___sy4Xl.lc-Switch-module__switch__track--on___aOMWe {
  background-color: var(--action-positive-hover);
}

.lc-Switch-module__switch___AaA2k:hover .lc-Switch-module__switch__track--enabled___sy4Xl.lc-Switch-module__switch__track--off___NAisj {
  background-color: var(--action-neutral-hover);
}

.lc-Switch-module__switch--large___pSoJ7 {
  width: 44px;
  height: 24px;
}

.lc-Switch-module__switch--compact___OGFIX {
  width: 32px;
  height: 16px;
}

.lc-Switch-module__switch--medium___EcZwT {
  width: 36px;
  height: 20px;
}

.lc-Switch-module__switch__input___NiiOR {
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: 0;
}

.lc-Switch-module__switch__input--disabled___Po8gh {
  cursor: not-allowed;
}

.lc-Switch-module__switch__input___NiiOR:focus + .lc-Switch-module__switch__container___79F7W .lc-Switch-module__switch__track___2wr2M {
  box-shadow: 0 0 1px 2px rgb(var(--color-action-default-rgb) .7);
}

.lc-Switch-module__switch__container___79F7W {
  pointer-events: none;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.lc-Switch-module__switch__track___2wr2M {
  border-radius: 24px;
  flex: 1;
  height: 100%;
  transition-property: background-color;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.33, 0, .67, 1);
}

.lc-Switch-module__switch__track--on___aOMWe.lc-Switch-module__switch__track--enabled___sy4Xl {
  background-color: var(--action-positive-default);
}

.lc-Switch-module__switch__track--on___aOMWe.lc-Switch-module__switch__track--disabled___HkDhl {
  background-color: var(--action-positive-disabled);
}

.lc-Switch-module__switch__track--off___NAisj.lc-Switch-module__switch__track--enabled___sy4Xl {
  background-color: var(--action-neutral-default);
}

.lc-Switch-module__switch__track--off___NAisj.lc-Switch-module__switch__track--disabled___HkDhl {
  background-color: var(--surface-tertiary-disabled);
}

.lc-Switch-module__switch__slider___WHd-- {
  background: var(--content-locked-white);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition-property: left, right;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.33, 0, .67, 1);
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.lc-Switch-module__switch__slider--large___WoEgi {
  width: 20px;
  height: 20px;
}

.lc-Switch-module__switch__slider--large--on___7Qp3G {
  left: 21px;
  right: 3px;
}

.lc-Switch-module__switch__slider--large--off___iRMmD {
  left: 3px;
  right: 21px;
}

.lc-Switch-module__switch__slider--compact___uZ-lS {
  width: 12px;
  height: 12px;
}

.lc-Switch-module__switch__slider--compact--on___FykE3 {
  left: 18px;
  right: 2px;
}

.lc-Switch-module__switch__slider--compact--off___s5yR8 {
  left: 2px;
  right: 18px;
}

.lc-Switch-module__switch__slider--medium___uhptz {
  width: 16px;
  height: 16px;
}

.lc-Switch-module__switch__slider--medium--on___SfeAV {
  left: 17px;
  right: 3px;
}

.lc-Switch-module__switch__slider--medium--off___eXJJz {
  left: 3px;
  right: 17px;
}

.lc-Switch-module__switch__loader___FE-fA {
  width: 100%;
  height: 100%;
}

.lc-Switch-module__switch__loader--compact___3imUh {
  padding: 1.5px;
}

.lc-Switch-module__switch__loader--medium___2zi05 {
  padding: 2px;
}

.lc-Switch-module__switch__loader--large___vLRFa {
  padding: 3px;
}

.lc-Switch-module__switch__loader___FE-fA > div {
  width: 100%;
  height: 100%;
}

.lc-Switch-module__switch__loader___FE-fA div:last-child {
  border-width: 2px;
}

.lc-Switch-module__switch__icon___-O1qY {
  color: var(--content-locked-black);
}

.lc-Tab-module__tab___fME7u {
  cursor: pointer;
  color: var(--content-basic-primary);
  background: none;
  border: 0;
  outline: none;
  flex-direction: row;
  align-items: center;
  margin: 0 8px;
  padding: 0 12px 1px;
  transition: color .25s;
  display: flex;
  position: relative;
  overflow: visible;
}

.lc-Tab-module__tab___fME7u:before {
  z-index: 1;
  border-radius: var(--radius-3);
  background-color: var(--action-primary-default);
  content: "";
  height: 0;
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
}

.lc-Tab-module__tab___fME7u:hover {
  color: var(--action-primary-default);
  text-decoration: none;
}

.lc-Tab-module__tab___fME7u:hover .lc-Tab-module__tab__count___gc8tA {
  color: var(--action-primary-default);
}

.lc-Tab-module__tab--compact___4qead {
  height: 32px;
}

.lc-Tab-module__tab--medium___bHnGV {
  height: 40px;
}

.lc-Tab-module__tab--large___JL30g {
  height: 48px;
}

.lc-Tab-module__tab__count___gc8tA {
  color: var(--content-basic-secondary);
  padding-left: 3px;
  transition: color .25s;
  display: inline-block;
}

.lc-Tab-module__tab__badge___W9c-T {
  margin-left: 4px;
}

.lc-Tab-module__tab--selected___c0EkL {
  color: var(--action-primary-default);
}

.lc-Tab-module__tab--selected___c0EkL:before {
  height: 3px;
}

.lc-Tab-module__tab--selected___c0EkL .lc-Tab-module__tab__count___gc8tA {
  color: var(--action-primary-default);
}

.lc-Tab-module__tab--disabled___URdTh {
  cursor: not-allowed;
  color: var(--content-basic-disabled);
}

.lc-Tab-module__tab--disabled___URdTh:hover, .lc-Tab-module__tab--disabled___URdTh:hover .lc-Tab-module__tab__count___gc8tA, .lc-Tab-module__tab--disabled___URdTh .lc-Tab-module__tab__count___gc8tA {
  color: var(--content-basic-disabled);
}

.lc-TabsWrapper-module__tabs___Y2xyD {
  border-bottom: 1px solid var(--border-subtle);
  position: relative;
}

.lc-TabsWrapper-module__tabs__list___i6tVO {
  flex-direction: row;
  margin: 0 -8px;
  display: flex;
  position: relative;
}

.lc-TagInput-module__tag-input___CLVY- {
  box-sizing: border-box;
  border: 1px solid var(--border-basic-primary);
  border-radius: var(--radius-3);
  background: var(--surface-primary-default);
  color: var(--content-basic-primary);
  will-change: border-color;
  background-clip: padding-box;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 3px 4px;
  font-size: 15px;
  transition: border .25s ease-in-out;
  display: flex;
  overflow-y: auto;
}

.lc-TagInput-module__tag-input___CLVY-::placeholder {
  color: var(--content-basic-disabled);
}

.lc-TagInput-module__tag-input___CLVY-:hover {
  border-color: var(--border-basic-hover);
  outline: 0;
}

.lc-TagInput-module__tag-input___CLVY-:focus-within {
  border-color: var(--action-primary-default);
  outline: 0;
}

.lc-TagInput-module__tag-input___CLVY-:disabled {
  border: 1px solid var(--border-basic-disabled);
  background-color: var(--surface-primary-disabled);
  cursor: not-allowed;
  color: var(--content-basic-disabled);
}

.lc-TagInput-module__tag-input--error___RP0SB, .lc-TagInput-module__tag-input--error___RP0SB:focus-within {
  border-color: var(--action-negative-default);
}

.lc-TagInput-module__tag-input___CLVY- * {
  box-sizing: border-box;
}

.lc-TagInput-module__tag-input___CLVY- > * {
  border-radius: var(--radius-1);
  margin: 2px;
}

.lc-TagInput-module__tag-input__input___dgEYB {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  color: var(--content-basic-primary);
  background: none;
  border-style: none;
  outline: 0;
  flex-grow: 1;
  width: auto;
  margin: 0 4px;
  padding: 0 0 0 4px;
  font-size: 15px;
}

.lc-TagInput-module__tag-input__input___dgEYB:focus {
  border-style: none;
}

.lc-TagInput-module__tag-input__input--medium___DYF7X {
  line-height: 28px;
}

.lc-TagInput-module__tag-input__input--large___G6iEO {
  line-height: 36px;
}

.lc-TagInput-module__tag-input__tag__content___x95-M {
  white-space: nowrap;
  border-style: none;
  outline: 0;
  padding: 0;
}

.lc-Toast-module__toast___j5Amn {
  box-sizing: border-box;
  border-radius: var(--radius-3);
  box-shadow: var(--shadow-pop-over);
  padding: var(--spacing-3);
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  min-width: 300px;
  max-width: 500px;
  font-size: 15px;
  line-height: 22px;
  display: inline-flex;
}

.lc-Toast-module__toast__content___HjaNw {
  width: 100%;
  min-width: 192px;
  font-weight: 600;
}

.lc-Toast-module__toast__actions___JWu-1 {
  align-items: center;
  display: inline-flex;
}

.lc-Toast-module__toast__actions__button___Iblrl {
  margin-left: 8px;
}

.lc-Toast-module__toast__actions__button--success___5JmFw {
  color: var(--content-locked-white);
}

.lc-Toast-module__toast__actions__button--warning___zGQ-Q {
  color: var(--content-locked-black);
}

.lc-Toast-module__toast__actions__button--error___PuTv3, .lc-Toast-module__toast__actions__button--info___fNtsj {
  color: var(--content-locked-white);
}

.lc-Toast-module__toast__actions--custom___lm-pM, .lc-Toast-module__toast__actions--custom___lm-pM:hover {
  white-space: nowrap;
  color: inherit;
}

.lc-Toast-module__toast--success___7UC6m {
  background: var(--surface-accent-emphasis-high-positive);
  color: var(--content-locked-white);
}

.lc-Toast-module__toast--warning___pteb7 {
  background: var(--surface-accent-emphasis-high-warning);
  color: var(--content-locked-black);
}

.lc-Toast-module__toast--error___trg-- {
  background: var(--surface-accent-emphasis-high-negative);
  color: var(--content-locked-white);
}

.lc-Toast-module__toast--info___IOp44 {
  background: var(--surface-accent-emphasis-high-info);
  color: var(--content-locked-white);
}

.lc-Toast-module__toast-wrapper___Oiwqp {
  z-index: 120;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: absolute;
}

.lc-Toast-module__toast-wrapper--fixed___QYxRI {
  position: fixed;
}

.lc-Toast-module__toast-wrapper--block___j0kGt {
  position: static;
}

.lc-Toast-module__toast-wrapper--horizontal-center___t9AFd {
  left: 50%;
  transform: translate(-50%);
}

.lc-Toast-module__toast-wrapper--horizontal-left___-GY4I {
  left: 5px;
}

.lc-Toast-module__toast-wrapper--horizontal-right___VVMgm {
  right: 5px;
}

.lc-Toast-module__toast-wrapper--vertical-top___uBfEG {
  top: 5px;
}

.lc-Toast-module__toast-wrapper--vertical-top___uBfEG .lc-Toast-module__toast___j5Amn {
  margin-bottom: 10px;
}

.lc-Toast-module__toast-wrapper--vertical-top___uBfEG .lc-Toast-module__toast-appear--slide___G7GAp {
  transform: translateY(-100%) !important;
}

.lc-Toast-module__toast-wrapper--vertical-top___uBfEG .lc-Toast-module__toast-appear--slide___G7GAp.lc-Toast-module__toast-appear-active--slide___brfgK {
  transition: transform .2s ease-in;
  transform: translateY(0) !important;
}

.lc-Toast-module__toast-wrapper--vertical-top___uBfEG .lc-Toast-module__toast-exit--slide___aqiBq {
  transform: translateY(0) !important;
}

.lc-Toast-module__toast-wrapper--vertical-top___uBfEG .lc-Toast-module__toast-exit--slide___aqiBq.lc-Toast-module__toast-exit-active--slide___WryMi {
  transition: transform .2s ease-in;
  transform: translateY(-100%) !important;
}

.lc-Toast-module__toast-wrapper--vertical-bottom___erPuH {
  bottom: 5px;
}

.lc-Toast-module__toast-wrapper--vertical-bottom___erPuH .lc-Toast-module__toast___j5Amn {
  margin-top: 10px;
}

.lc-Toast-module__toast-wrapper--vertical-bottom___erPuH .lc-Toast-module__toast-appear--slide___G7GAp {
  transform: translateY(100%) !important;
}

.lc-Toast-module__toast-wrapper--vertical-bottom___erPuH .lc-Toast-module__toast-appear--slide___G7GAp.lc-Toast-module__toast-appear-active--slide___brfgK {
  transition: transform .2s ease-in;
  transform: translateY(0) !important;
}

.lc-Toast-module__toast-wrapper--vertical-bottom___erPuH .lc-Toast-module__toast-exit--slide___aqiBq {
  transform: translateY(0) !important;
}

.lc-Toast-module__toast-wrapper--vertical-bottom___erPuH .lc-Toast-module__toast-exit--slide___aqiBq.lc-Toast-module__toast-exit-active--slide___WryMi {
  transition: transform .2s ease-in;
  transform: translateY(100%) !important;
}

.lc-Toast-module__toast-wrapper--vertical-top___uBfEG .lc-Toast-module__toast-appear--fade___oUwHm, .lc-Toast-module__toast-wrapper--vertical-bottom___erPuH .lc-Toast-module__toast-appear--fade___oUwHm {
  opacity: 0;
}

.lc-Toast-module__toast-wrapper--vertical-top___uBfEG .lc-Toast-module__toast-appear--fade___oUwHm.lc-Toast-module__toast-appear-active--fade___D3--J, .lc-Toast-module__toast-wrapper--vertical-bottom___erPuH .lc-Toast-module__toast-appear--fade___oUwHm.lc-Toast-module__toast-appear-active--fade___D3--J, .lc-Toast-module__toast-wrapper--vertical-top___uBfEG .lc-Toast-module__toast-exit--fade___iDA1p, .lc-Toast-module__toast-wrapper--vertical-bottom___erPuH .lc-Toast-module__toast-exit--fade___iDA1p {
  opacity: 1;
  transition: opacity .2s ease-in;
}

.lc-Toast-module__toast-wrapper--vertical-top___uBfEG .lc-Toast-module__toast-exit--fade___iDA1p.lc-Toast-module__toast-exit-active--fade___tL7sb, .lc-Toast-module__toast-wrapper--vertical-bottom___erPuH .lc-Toast-module__toast-exit--fade___iDA1p.lc-Toast-module__toast-exit-active--fade___tL7sb {
  opacity: 0;
}

.lc-Textarea-module__textarea___g6U8F {
  box-sizing: border-box;
  border: 1px solid var(--border-basic-primary);
  border-radius: var(--radius-3);
  background: var(--surface-primary-default);
  color: var(--content-basic-primary);
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  display: inline-block;
}

.lc-Textarea-module__textarea___g6U8F:hover {
  border-color: var(--border-basic-hover);
}

.lc-Textarea-module__textarea--focused___1vtEx, .lc-Textarea-module__textarea--focused___1vtEx:hover {
  border-color: var(--action-primary-default);
}

.lc-Textarea-module__textarea--disabled___xXaGv, .lc-Textarea-module__textarea--disabled___xXaGv:hover {
  border-color: var(--border-basic-disabled);
  background-color: var(--surface-primary-disabled);
  color: var(--content-basic-disabled);
}

.lc-Textarea-module__textarea--error___0EGuq, .lc-Textarea-module__textarea--error___0EGuq:hover {
  border-color: var(--border-basic-negative);
}

.lc-Textarea-module__textarea___g6U8F textarea {
  border-radius: var(--radius-3);
  background: inherit;
  color: inherit;
  border: 0;
  outline: none;
  min-width: 125px;
  min-height: 32px;
  padding: 8px 12px;
  display: block;
}

.lc-FileUploadProgressActions-module__file-upload-progress-actions___qOBr2 {
  flex-wrap: nowrap;
  flex: 1 0 auto;
  align-items: center;
  margin-left: auto;
  display: inline-flex;
}

.lc-FileUploadProgressActions-module__file-upload-progress-actions__close-button___Eh4um, .lc-FileUploadProgressActions-module__file-upload-progress-actions__retry-button___DqFEo {
  color: var(--content-basic-primary);
  background-color: #0000;
  border: 0;
  border-radius: 4px;
  flex: 0 auto;
  justify-self: flex-end;
  align-items: center;
  margin: 0 0 0 8px;
  padding: 0;
  display: flex;
}

.lc-FileUploadProgressActions-module__file-upload-progress-actions__close-button___Eh4um:hover, .lc-FileUploadProgressActions-module__file-upload-progress-actions__retry-button___DqFEo:hover {
  cursor: pointer;
}

.lc-FileUploadProgress-module__file-upload-progress___xw8h8 {
  align-items: center;
  width: 100%;
  display: flex;
}

.lc-FileUploadProgress-module__file-upload-progress__icon___p8Rfz {
  height: 18px;
  margin-right: 8px;
}

.lc-FileUploadProgress-module__file-upload-progress__icon--success___X40uH {
  color: var(--content-basic-positive);
}

.lc-FileUploadProgress-module__file-upload-progress__wrapper___01Nkq {
  flex-direction: column;
  flex: 0 auto;
  align-self: flex-end;
  width: 100%;
  display: flex;
}

.lc-FileUploadProgress-module__file-upload-progress__wrapper--with-icon___GSXmA {
  width: calc(100% - 28px);
}

.lc-FileUploadProgress-module__file-upload-progress__wrapper__header___mYBGK {
  justify-content: space-between;
  height: 21px;
  display: flex;
}

.lc-FileUploadProgress-module__file-upload-progress__wrapper__header___mYBGK:hover .lc-FileUploadProgress-module__file-upload-progress__wrapper__header__actions___tvhB3 {
  display: block;
}

.lc-FileUploadProgress-module__file-upload-progress__wrapper__header__title___2IVuv {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--content-basic-primary);
  justify-self: flex-start;
  width: 100%;
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 1.3;
  overflow: hidden;
}

.lc-FileUploadProgress-module__file-upload-progress__wrapper__header__title--success___E6DQl {
  margin-bottom: 0;
  line-height: normal;
}

.lc-FileUploadProgress-module__file-upload-progress__wrapper__header__title--error___5BPpI {
  color: var(--content-basic-negative);
}

.lc-FileUploadProgress-module__file-upload-progress__wrapper__header__actions___tvhB3 {
  display: none;
}

.lc-UploadBar-module__upload-bar___6Or8h {
  box-sizing: border-box;
  border: solid 1px var(--border-basic-primary);
  background-color: var(--surface-primary-default);
  padding: var(--spacing-3);
  color: var(--content-basic-primary);
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 2px 6px #0003;
}

.lc-UploadBar-module__upload-bar__loader-test___aZb2J {
  width: 20px;
  height: 20px;
}

.lc-UploadBar-module__upload-bar--error___MzvhT {
  color: var(--content-basic-negative);
}

.lc-UploadBar-module__upload-bar__wrapper___MAUeH {
  box-sizing: border-box;
  cursor: pointer;
  place-content: center space-between;
  display: flex;
}

.lc-UploadBar-module__upload-bar__wrapper__header___x9fen {
  align-items: center;
  width: 100%;
  font-size: 15px;
  display: flex;
}

.lc-UploadBar-module__upload-bar__wrapper__header__icon___Aetm5 {
  margin-right: var(--spacing-2);
}

.lc-UploadBar-module__upload-bar__wrapper__header__title___BNuDu {
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-self: flex-start;
  width: 100%;
  font-size: 14px;
  line-height: 1.3;
  overflow: hidden;
}

.lc-UploadBar-module__upload-bar__wrapper__header__collapse-button___3khMy {
  margin: 0;
  margin-left: var(--spacing-2);
  cursor: pointer;
  padding: var(--spacing-1);
  color: var(--content-basic-primary);
  background-color: #0000;
  border: 0;
  border-radius: 4px;
  flex: 0 auto;
  align-items: center;
  display: flex;
}

.lc-UploadBar-module__upload-bar__wrapper__header__actions-container___5Pj4y {
  align-items: center;
  height: 28px;
  display: flex;
}

.lc-UploadBar-module__upload-bar__wrapper__header__success-icon___ZLCdw {
  color: var(--content-basic-positive);
}

.lc-UploadBar-module__upload-bar__wrapper__header__error-icon___q-sZo {
  color: var(--content-basic-negative);
}

.lc-UploadBar-module__upload-bar__wrapper__header__loader___DKHu3 {
  width: 20px;
  height: 20px;
}

.lc-UploadBar-module__upload-bar__files___3W4nx {
  box-sizing: border-box;
  max-height: 200px;
  display: flex;
}

.lc-UploadBar-module__upload-bar__files--enter___zGq19 {
  max-height: 0;
}

.lc-UploadBar-module__upload-bar__files--enter-active___-dMVb {
  max-height: 200px;
  transition: max-height .3s cubic-bezier(.14, 0, 0, 1);
}

.lc-UploadBar-module__upload-bar__files--exit___MXBRY {
  max-height: 200px;
}

.lc-UploadBar-module__upload-bar__files--exit-active___krGW5, .lc-UploadBar-module__upload-bar__files--exit-done___2I72G {
  max-height: 0;
  transition: max-height .3s cubic-bezier(.14, 0, 0, 1);
}

.lc-UploadBar-module__upload-bar__files-wrapper___93bs8 {
  box-sizing: border-box;
  padding-top: var(--spacing-4);
  max-height: inherit;
  flex-direction: column;
  width: 100%;
  display: flex;
}

.lc-UploadBar-module__upload-bar__files__list___QJFEq {
  padding-right: var(--spacing-5);
  height: 100%;
  overflow-y: auto;
}

.lc-UploadBar-module__upload-bar__files__list___QJFEq > div:not(:last-child) {
  margin-bottom: var(--spacing-4);
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  background-color: var(--background);
  padding: var(--spacing-0);
  margin: var(--spacing-0);
  color: var(--content-default);
  width: 100%;
}

#root {
  width: 100%;
}

/*# sourceMappingURL=index.3ed760b9.css.map */
