/* source-sans-pro-latin-200-normal */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url(./files/source-sans-pro-latin-200-normal.woff2) format('woff2'), url(./files/source-sans-pro-latin-200-normal.woff) format('woff');
}

/* source-sans-pro-latin-300-normal */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(./files/source-sans-pro-latin-300-normal.woff2) format('woff2'), url(./files/source-sans-pro-latin-300-normal.woff) format('woff');
}

/* source-sans-pro-latin-400-normal */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(./files/source-sans-pro-latin-400-normal.woff2) format('woff2'), url(./files/source-sans-pro-latin-400-normal.woff) format('woff');
}

/* source-sans-pro-latin-600-normal */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(./files/source-sans-pro-latin-600-normal.woff2) format('woff2'), url(./files/source-sans-pro-latin-600-normal.woff) format('woff');
}

/* source-sans-pro-latin-700-normal */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(./files/source-sans-pro-latin-700-normal.woff2) format('woff2'), url(./files/source-sans-pro-latin-700-normal.woff) format('woff');
}

/* source-sans-pro-latin-900-normal */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(./files/source-sans-pro-latin-900-normal.woff2) format('woff2'), url(./files/source-sans-pro-latin-900-normal.woff) format('woff');
}