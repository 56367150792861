html {
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

body {
    width: 100%;
    background-color: var(--background);
    padding: var(--spacing-0);
    margin: var(--spacing-0);
    color: var(--content-default)
}

#root {
    width: 100%;
}
